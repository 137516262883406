/**
 * @format
 */

import { Action, createReducer, on } from '@ngrx/store';
import * as BillingActions from './billing.actions';
import {
  ChargebeeCustomer,
  isSubscriptionActive,
  ChargebeeSubscription,
  getTopSubscriptionInfo,
  SubscriptionLevel,
  SubscriptionStatus,
  Plan,
  AddOn,
} from '@billing/shared/billing.model';
import * as ResetActions from '@store/actions/reset.actions';
import * as UserActions from '@store/actions/user.actions';
import * as ProjectActions from '@store/actions/projects.actions';
import { ProjectGroup } from '@store/reducers/projects.reducers';
import { checkIsMember } from '@projects/shared/project.model';

const DEBUG_LOGS = false;
export const featureKey = 'billing';

export interface State {
  loaded: boolean;
  status: SubscriptionStatus;
  level: SubscriptionLevel;
  userId: string; // the chargebee userId - needed?
  // subscription: {};
  minutes: number;
  code: string;

  proSubId: string;
  /** the ones I am paying for */
  eventSubIds: string[];
  chargebeeSubscriptions: ChargebeeSubscription[];
  chargebeeCustomer: ChargebeeCustomer;
  // chargebee?: {
  //   didInit: boolean;
  //   cbInstance: any;
  //   didCreatePortalSession: boolean;
  //   userId: string;
  //   userEmail: string;
  // }
  plans: Plan[];
  addons: AddOn[];
}

export const initialState = {
  loaded: false,
  status: SubscriptionStatus.InActive,
  level: SubscriptionLevel.Loading,
  userId: '',
  minutes: 0,
  code: '',
  proSubId: '',
  eventSubIds: [],
  // subscription: {},
  chargebeeSubscriptions: [],
  chargebeeCustomer: {},
  plans: [],
  addons: [],
};

const billingReducer = createReducer(
  initialState,
  on(BillingActions.loadPlansSuccess, (state, { plans = [], addons }) => ({
    ...state,
    plans,
    addons,
  })),
  on(BillingActions.addCode, (state, { code }) => ({
    ...state,
    code,
  })),
  on(BillingActions.doneLoadingNoUser, (state) => ({
    ...state,
    loaded: true,
    status: state.status === initialState.status ? SubscriptionStatus.InActive : state.status,
    level: state.level === initialState.level ? SubscriptionLevel.Unsubscribed : state.level,
  })),

  on(BillingActions.loadCustomerSubscriptionsSuccess, (state, { customer, subscriptions }) => {
    let hasEventSubs = state.eventSubIds?.length > 0;
    const newSubs = Array.isArray(subscriptions) && subscriptions.length > 0 ? subscriptions : [];
    const allSubs = [...(state.chargebeeSubscriptions || []), ...newSubs];
    const activeSubs = allSubs.filter((sub) => isSubscriptionActive(sub));
    const status = activeSubs.length > 0 ? SubscriptionStatus.Active : initialState.status;

    if (activeSubs.length > 0) {
      const {
        level,
        proSubscriptionId: proSubId,
        eventSubscriptionIds: eventSubIds,
      } = getTopSubscriptionInfo(activeSubs);

      const newEventSubIds = hasEventSubs
        ? eventSubIds.filter((subscriptionId) => !state.eventSubIds.find((id) => id === subscriptionId))
        : eventSubIds;

      hasEventSubs = newEventSubIds.length > 0 || state.eventSubIds.length > 0;

      // determine SubscriptionLevel for crew on projects with active events in Billing.Effects
      DEBUG_LOGS &&
        console.warn(`[BillingStore] success:`, {
          eventSubIds,
          hasEventSubs,
          activeSubs,
          level,
          status,
          customer,
          subscriptions,
        });
      return {
        ...state,
        status,
        level,
        userId: customer?.id ?? state.userId,
        proSubId,
        eventSubIds: [...state.eventSubIds, ...newEventSubIds],
        chargebeeSubscriptions: allSubs,
        chargebeeCustomer: customer,
        loaded: true,
      };
    }

    DEBUG_LOGS && console.log(`[BillingStore] success (no new subs):`, { state, customer, subscriptions });

    return {
      ...state,
      status,
      level: activeSubs.length > 0 ? state.level : SubscriptionLevel.Unsubscribed,
      userId: customer?.id ?? state.userId,
      chargebeeSubscriptions: allSubs,
      chargebeeCustomer: customer,
      loaded: true,
    };
  }),

  // on(BillingActions.resetScore, state => ({ home: 0, away: 0 })),
  // on(BillingActions.setScores, (state, { game }) => ({ home: game.home, away: game.away }))
  on(BillingActions.reset, () => ({ ...initialState })),

  // on(UserActions.logout, state => ({ ...initialState })),
  /**
   * User Auth should modify content, just clear for rebuild
   */
  on(ResetActions.resetStoreOnLogout, UserActions.logout, UserActions.loginSuccess, (state) => ({
    ...initialState,
    // keep the plans
    plans: state.plans || [],
    addons: state.addons || [],
  })),

  on(UserActions.updateUserData, (state, { user }) => {
    if (user?.userId && state.userId === user.userId) {
      // this is me
      DEBUG_LOGS && console.log('updateUserData', { state, user });
      const status =
        user &&
        (user.subscriptionStatus === SubscriptionStatus.Active ||
          user.subscriptionStatus === SubscriptionStatus.InActive)
          ? user.subscriptionStatus
          : state.status;
      const level = user?.subscriptionLevel ?? state.level;
      return {
        ...state,
        status,
        level,
        userId: user?.userId ?? state.userId,
      };
    }
    return state;
  }),

  on(UserActions.subUpdate, (state, { user }) => {
    if (user?.userId && state.userId === user.userId) {
      // this is me
      const status =
        user &&
        (user.subscriptionStatus === SubscriptionStatus.Active ||
          user.subscriptionStatus === SubscriptionStatus.InActive)
          ? user.subscriptionStatus
          : state.status;
      const level = user?.subscriptionLevel ?? state.level;
      DEBUG_LOGS && console.log(`[BillingStore] user subUpdate:`, { status, level });
      return {
        ...state,
        status,
        level,
      };
    } else {
      // ignoring users not us - handle them in members.reducers
      return state;
    }
  }),

  on(ProjectActions.loadByIdSuccess, (state, { project, userId }) => {
    if (!project || !project.id) return state;
    const iAmActiveMember = userId && Array.isArray(project.members) ? project.members.find((m) => m.userId === userId)?.isActive : false;
    if (iAmActiveMember && project.subscriptionStatus === SubscriptionStatus.Active && project.subscriptionId?.length > 0) {
      if (state.status !== SubscriptionStatus.Active) {
        return {
          ...state,
          status: SubscriptionStatus.Active,
          level: SubscriptionLevel.Crew,
          // do not add crew projects to my eventSubIds
          // eventSubIds: [...state.eventSubIds, ...newEventSubIds],
          userId: state.userId ? state.userId : userId
        };
      }
      return {
        ...state,
        // status: SubscriptionStatus.Active, // already active per if
        level: state.level === initialState.level ? SubscriptionLevel.Crew : state.level,
        // do not add crew projects to my eventSubIds
        // eventSubIds: [...state.eventSubIds, ...newEventSubIds],
        userId: state.userId ? state.userId : userId
      };
    }
    return state;
  }),

  on(ProjectActions.loadSuccess, (state, { projects, listId }) => {
    if (listId === ProjectGroup.Mine) {
      const activeSubs = projects.filter(
        (p) => p?.subscriptionStatus === SubscriptionStatus.Active && p?.subscriptionId?.length > 0
      );
      if (activeSubs.length > 0) {
        DEBUG_LOGS && console.log(`found activeSub in MyProjects -> Crew`, { activeSubs, state, projects });
        // const newEventSubIds = activeSubs.map((sub) => sub.subscriptionId).filter(subscriptionId => !state.eventSubIds.find(id => id === subscriptionId));
        // verify the activeSubs[which] status & level?
        if (state.status !== SubscriptionStatus.Active) {
          return {
            ...state,
            status: SubscriptionStatus.Active,
            level: SubscriptionLevel.Crew,
            // do not add crew projects to my eventSubIds
            // eventSubIds: [...state.eventSubIds, ...newEventSubIds],
          };
        }
        return {
          ...state,
          // status: SubscriptionStatus.Active, // already active per if
          level: state.level === initialState.level ? SubscriptionLevel.Crew : state.level,
          // do not add crew projects to my eventSubIds
          // eventSubIds: [...state.eventSubIds, ...newEventSubIds],
        };
      }
    }
    return state;
  }),

  // consider moving to BillingEffect so we can include ProjectEntity data instead of relying on api including
  on(ProjectActions.subUpdate, (state, { project, userId }) => {
    console.warn(`Billing.ProjectUpdate`, { project, userId });
    // check if i am a member
    if (project && project.id && checkIsMember(state.userId, project)) {
      if (state.status !== SubscriptionStatus.Active) {
        if (project?.subscriptionStatus === SubscriptionStatus.Active && project?.subscriptionId?.length > 0) {
          DEBUG_LOGS && console.log(`assuming activeSub in project -> Crew`, { state, project });
          return {
            ...state,
            status: SubscriptionStatus.Active,
            level: SubscriptionLevel.Crew,
            // don't save eventSubIds if they are not mine
            // eventSubIds: [...state.eventSubIds.filter(id => id !== project.subscriptionId), project.subscriptionId],
          };
        }
      }
    }
    // ignoring items we don't care about..
    return state;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return billingReducer(state, action);
}
