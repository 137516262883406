<!-- @format -->

<footer>
  <div class="footer container">
    <div class="footer__side footer__side--left">
      <div>
        <img class="logo" src="/assets/images/filmstacker-logo.png" alt="Filmstacker" />
      </div>
    </div>
    <div class="footer__side">
      <div class="footer__side__item">
        <a routerLink="/about">{{ 'COMMON.ABOUT' | translate }}</a>
      </div>
      <!-- <div class="footer__side__item">
        <a routerLink="/support">{{ 'COMMON.SUPPORT' | translate }}</a>
      </div> -->
      <div class="footer__side__item">
        <a routerLink="/community-rules">{{ 'COMMON.COMMUNITY_GUIDELINES' | translate }}</a>
      </div>
      <div class="footer__side__item">
        <a routerLink="/privacy">{{ 'POLICIES.PRIVACY_POLICY' | translate }}</a>
      </div>
      <div class="footer__side__item">
        <a routerLink="/terms">{{ 'POLICIES.TERMS_USE' | translate }}</a>
      </div>
      <div class="footer__side__item">&copy;{{ copyrightYear }} Filmstacker Inc. {{ 'POLICIES.RIGHTS_RESERVED' | translate }}.</div>
    </div>
  </div>
  <div class="tab-bottom-spacer"></div>
</footer>
