/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  State,
  selectMinutes,
  selectSubscriptionStatus,
  SubscriptionViewModel,
  selectIsPro,
  selectEventSubscriptionProjects,
} from '@billing/store';
import { BillingService } from '../../services';
import { ChargebeePlanId } from '@billing/shared/billing.model';
import { CheckoutModalComponent } from '../checkout-modal/checkout-modal.component';
import { UserService } from '@services/user.service';
import { SIGNUP_RETURN_URL_PRO } from '@onboarding/shared/onboarding.model';
import { ENABLE_BILLING_MINUTES } from '@app/app.config';
import { PlansModalComponent } from '../plans-modal/plans-modal.component';
import { Project } from '@projects/shared/project.model';
import { ProjectService } from '@projects/shared/services';
import { PROJECT_DETAIL_ROUTE } from '@app/app-routing.module';

@Component({
  selector: 'fs-subscription-detail',
  templateUrl: './subscription-detail.component.html',
  styleUrls: ['./subscription-detail.component.scss'],
})
export class SubscriptionDetailComponent implements OnInit, OnDestroy {
  subscriptionVm$: Observable<SubscriptionViewModel> = this.store.select(selectSubscriptionStatus);
  // proSubscriptionId$: Observable<string> = this.store.select(selectProSubscriptionId);
  // eventSubscriptionIds$: Observable<string[]> = this.store.select(selectEventSubscriptionIds);
  minutes$: Observable<number> = this.store.select(selectMinutes);
  isPro$: Observable<boolean> = this.store.select(selectIsPro);

  minutesEnabled = ENABLE_BILLING_MINUTES;

  /** paid with my credit card */
  myEventProjects$: Observable<(Project & { canEdit: boolean })[]>;
  routeProjectDetail = `/${PROJECT_DETAIL_ROUTE}`;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private userService: UserService,
    private projectService: ProjectService,
    private billingService: BillingService,
    private modalCtrl: ModalController,
    private navCtrl: NavController
  ) {}

  ngOnInit(): void {
    // billingService.loadPlans happens in app.component.initializeApp

    // just MY Events
    this.myEventProjects$ = this.store
      .select(selectEventSubscriptionProjects)
      .pipe(filter((p) => typeof p !== 'undefined'))
      .pipe(
        withLatestFrom(this.userService.userId$),
        map(([projects, userId]) =>
          projects.map((project) => ({
            ...project,
            canEdit: this.projectService.isProjectAdmin(project, userId),
          }))
        )
      );

    // this.inviteType = getInviteTypeFromProject(project);
    // const { eventType, canBeActive, eventIsActive, eventDate, eventConfig, subscriptionBy, subscriptionLevel, subscriptionMinutes, subscriptionStatus } = getEventConfig(project);
    // console.log('getEventConfig=', { eventType, canBeActive, eventIsActive, eventDate, eventConfig, subscriptionBy, subscriptionLevel, subscriptionMinutes, subscriptionStatus })
    // this.eventType = eventType;
    // this.projectSubActive = subscriptionStatus === SubscriptionStatus.Active;
    // this.canCreateEvent = this.projectSubActive ? false : canCreateEvent(project);
    // this.projectSubLevel = this.projectSubActive ? subscriptionLevel : '';
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  subscriptionActionClick(subscription: SubscriptionViewModel) {
    if (subscription.isActive && subscription?.proSubId) {
      this.editSubscription(subscription.proSubId);
      // } else if (subscription.isActive && subscription?.eventsSubIds) {
    } else {
      this.subscribe();
    }
  }

  async editEventSubscription(subscription: SubscriptionViewModel, project: Project) {
    // console.log({event, subscription, project})
    if (project?.subscriptionId && subscription.eventsSubIds.includes(project.subscriptionId)) {
      // we have a valid event subID - open that edit
      this.editSubscription(project.subscriptionId);
    } else {
      console.log('Not Authorized to editEventSubscription..');
      this.navCtrl.navigateForward(SIGNUP_RETURN_URL_PRO);
    }
  }

  /**
   * Before calling me, validate that subscription.isActive
   */
  async editSubscription(subscriptionId: string) {
    if (subscriptionId) {
      this.billingService.openEditSubscription(subscriptionId);
      return;
      // this.userService.userId$.pipe(take(1)).subscribe(async (userId) => {
      //   const iframeSrc = await this.billingService.getEditCheckoutHostedPageId({ userId, subscriptionId });
      //   const modal = await this.modalCtrl.create({
      //     component: CheckoutModalComponent,
      //     componentProps: {
      //       // id: subscription.id,
      //       userId,
      //       iframeSrc
      //     },
      //     cssClass: 'fullheight-iframe-modal',
      //   });
      //   await modal.present();

      //   const { data } = await modal.onWillDismiss();
      //   console.log(data);
      // });
    } else {
      this.navCtrl.navigateForward(SIGNUP_RETURN_URL_PRO);
    }
  }

  async subscribe() {
    const modal = await this.modalCtrl.create({
      component: PlansModalComponent,
      componentProps: {
        // userId,
        // planId,
        // priceId,
        // referralCode,
        // projectId,
        // projectTitle,
        // eventDate,
        // successRedirectPath,
      },
      cssClass: 'fullheight-iframe-modal',
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      console.log('PlansModal dismissed:', data);
    }
  }

  /*
  TODO: refactor all of the below:
  */

  /** instead of this: use the checkout-button.component! */
  async openCheckout() {
    // how do we determine this here? diff buttons, or allow from marketing site?
    const planId = ChargebeePlanId.Pro;
    this.userService.userId$.pipe(take(1)).subscribe(async (userId) => {
      const modal = await this.modalCtrl.create({
        component: CheckoutModalComponent,
        componentProps: {
          planId,
          userId,
        },
        cssClass: 'fullheight-iframe-modal',
      });
      return await modal.present();
    });

    // this.billingService.updateBillingInfoWithHostedPage('qx0lQY94Hyzrp2CK6zyolaVbqm4Imyie');
    try {
      // this.billingService.openCheckout(planId);
    } catch (error) {
      console.warn(`[SubscriptionDetail] openPortal caught:`, error);
    }
  }

  /**
   * billingService.initUser already done in the user-menu, do not need it again
   */
  // ngAfterViewInit() {
  //   // console.log(`[SubscriptionDetail] afterViewInit -> initUser...`);
  //   // this.billingService.initUser();
  //   // this.billingService.generatePortalSession();
  // }

  /**
   * @todo: jd
   */
  viewPlans() {
    //   console.log(`[SubscriptionDetail] getPlans`);
    //   this.billingService.getPlans();
  }

  addMinutes() {
    // const planId = 'clip-minutes';  // @todo: jd
    // const planId = ChargebeePlanId.Pro;
    try {
      console.warn(`todo: you'll need a planId here and addons`);
      // this.billingService.openCheckout(planId);
    } catch (error) {
      console.warn(`[SubscriptionDetail] openPortal caught:`, error);
    }
  }

  openBillingInfo() {
    try {
      this.billingService.openBillingInfo();
    } catch (error) {
      console.warn(`[SubscriptionDetail] openBillingInfo caught:`, error);
    }
  }

  openPaymentMethods() {
    try {
      this.billingService.openPaymentMethods();
    } catch (error) {
      console.warn(`[SubscriptionDetail] openBillingInfo caught:`, error);
    }
  }

  openPurchaseHistory() {
    try {
      this.billingService.openPurchaseHistory();
    } catch (error) {
      console.warn(`[SubscriptionDetail] openBillingInfo caught:`, error);
    }
  }

  // openPortal(event) {
  //   try {
  //     this.billingService.openPortal({});
  //   } catch (error) {
  //     console.warn(`[SubscriptionDetail] openPortal caught:`, error);
  //   }
  // }
}
