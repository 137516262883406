/** @format */

import { ENABLE_BILLING } from '@app/app.config';
import { ShareableItem } from '@app/core/social-sharing/social-sharing.model';
import { SubscriptionLevel, SubscriptionStatus } from '@billing/shared/billing.model';
import { ITEM_TYPE } from '@shared/models/layout.model';
import { filestackConfig } from 'src/environments/environment';

export const DEFAULT_PROJECT_POSTER = 'https://content.filmstacker.com/public/posters/project-default-hero.jpg';
export const DEFAULT_PROJECT_POSTER_WEDDINGS = 'https://content.filmstacker.com/public/posters/project-default-weddings-hands@1.jpg';
export const PROJECT_HERO_UPLOAD_CDN = filestackConfig.storeTo.poster.cdn; // 'https://content.filmstacker.com/';
export const PROJECT_HERO_UPLOAD_BUCKET = filestackConfig.storeTo.poster.container; // 'content.filmstacker.com';
// export const PROJECT_HERO_UPLOAD_PATH = filestackConfig.storeTo.poster.path; // 'public/posters/'; // <project-id>

export enum PROJECT_MEMBER_ROLE {
  OWNER = 'OWNER',
  EXECUTIVE = 'EXECUTIVE',
  PRODUCER = 'PRODUCER',
  CREW = 'CREW',
}

export const sortProjectTitleAlpha = (a: Project, b: Project) =>
  a.title && b.title && a.title.toUpperCase() < b.title.toUpperCase()
    ? -1
    : a.title.toUpperCase() > b.title.toUpperCase()
    ? 1
    : 0;

export const getProjectConfig = (project: Project): ProjectConfig => {
  try {
    if (project && project.config) {
      if (typeof project.config === 'string') {
        return JSON.parse(project.config);
      }
      return project.config;
    }
    return {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

/** check if i am an owner or active member */
export const checkIsMember = (userId: string, project: Project): boolean =>
  userId &&
  project &&
  (project.owner === userId ||
    (Array.isArray(project.members) && project.members.some((m) => m.userId === userId && m.isActive)));

export const checkIsAdmin = (userId: string, project: Project): boolean =>
  userId &&
  project &&
  (project.owner === userId ||
    (Array.isArray(project.members) &&
      project.members.some(
        (m) =>
          m.userId === userId &&
          m.isActive &&
          (m.role === PROJECT_MEMBER_ROLE.PRODUCER ||
            m.role === PROJECT_MEMBER_ROLE.EXECUTIVE ||
            m.role === PROJECT_MEMBER_ROLE.OWNER)
      )));

/** get the member role */
export const getIsMemberRole = (userId: string, project: Project): PROJECT_MEMBER_ROLE | null => {
  if (!userId || !project) {
    return null;
  }
  if (project.owner === userId) {
    return PROJECT_MEMBER_ROLE.OWNER;
  }
  if (Array.isArray(project.members)) {
    const member = project.members.find((m) => m.userId === userId && m.isActive);
    if (member?.role) {
      return member.role;
    }
  }
  return null;
};

export enum ProjectEventType {
  None = 'NONE',
  Projects = 'PROJECT',
  // Basic = 'BASIC',
  Pro = 'PRO',
  Teams = 'TEAM',
  Event = 'EVENT',
  Weddings = 'WEDDING',
  Festivals = 'FESTIVAL',
}

/** currently only used by getEventType */
const getTemplateType = (project: Project): string => project?.template?.toUpperCase() ?? '';

/**
 * Get the Project.eventType from template or eventType
 */
export const getEventType = (project: Project): ProjectEventType => {
  const s = project?.eventType ?? getTemplateType(project);

  switch (s.toUpperCase()) {
    case 'PROJECT':
      return ProjectEventType.Projects;
    case 'PRO':
      return ProjectEventType.Pro;
    case 'TEAM':
    case 'TEAMS':
    // return ProjectEventType.Teams; // teams should be Events
    case 'EVENT':
    case 'EVENTS':
      return ProjectEventType.Event;
    case 'WEDDING':
    case 'WEDDINGS':
      return ProjectEventType.Weddings;
    case 'FESTIVAL':
      return ProjectEventType.Festivals;
    case 'NONE':
    default:
      return ProjectEventType.None;
  }
  // this works, but need a bit more flexibility due to template field variation..
  // const enumFromValue = <T extends Record<string, string>>(val: string, _enum: T) => {
  //   const enumName = (Object.keys(_enum) as Array<keyof T>).find(k => _enum[k] === val)
  //   if (!enumName) return _enum[0]
  //   // if (!enumName) throw Error() // here fail fast as an example
  //   return _enum[enumName]
  // }
  // return enumFromValue(s, ProjectEventType)
};

/**
 * Check if the Event Can Be Active
 * Currently ALL cases are YES - you can make any project an Event
 */
export const canEventTypeMakeActive = (project: Project): boolean => {
  switch (getEventType(project)) {
    case ProjectEventType.Pro:
    case ProjectEventType.Teams:
    case ProjectEventType.Event:
    case ProjectEventType.Weddings:
    case ProjectEventType.Festivals:
    case ProjectEventType.Projects:
    case ProjectEventType.None:
      return true;
    default:
      return true;
  }
};

/**
 * Get the default project poster based on the project type
 */
export const getProjectDefaultHeroUrl = (project: Project): string => {
  switch (getEventType(project)) {
    case ProjectEventType.Weddings:
      return DEFAULT_PROJECT_POSTER_WEDDINGS;
    case ProjectEventType.Pro:
    case ProjectEventType.Teams:
    case ProjectEventType.Event:
    case ProjectEventType.Festivals:
    case ProjectEventType.Projects:
    case ProjectEventType.None:
    default:
      return DEFAULT_PROJECT_POSTER;
  }
};


// part of project model

interface SubscriptionEventConfig {
  eventType: ProjectEventType;
  canBeActive: boolean;
  eventIsActive: boolean;
  eventDate: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventConfig: any;
  subscriptionId: string;
  subscriptionBy: string;
  subscriptionLevel: SubscriptionLevel;
  subscriptionMinutes: number;
  subscriptionStatus: SubscriptionStatus;
  expiresDate?: string; // Date ISO String
  renewsDate?: string; // Date ISO String
}

/** Check if the Event Is Active */
export const getEventConfig = (project: Project): SubscriptionEventConfig => {
  // https://filmstacker.sentry.io/issues/4419447997/?alert_rule_id=1165326&alert_type=issue&project=1724619&referrer=slack
  if (!project) {
    return {
      eventType: ProjectEventType.None,
      canBeActive: false,
      eventIsActive: false,
      eventDate: '',
      eventConfig: {},
      subscriptionId: '',
      subscriptionBy: '',
      subscriptionLevel: SubscriptionLevel.Unsubscribed,
      subscriptionMinutes: 0,
      subscriptionStatus: SubscriptionStatus.InActive,
    };
  }
  const {
    eventIsActive = false,
    eventDate,
    eventConfig = {},
    subscriptionId,
    subscriptionBy,
    subscriptionLevel,
    subscriptionMinutes,
    subscriptionStatus,
  } = project;
  return {
    eventType: getEventType(project),
    canBeActive: canEventTypeMakeActive(project),
    eventIsActive,
    eventDate,
    eventConfig,
    subscriptionId,
    subscriptionBy,
    subscriptionLevel:
      (subscriptionLevel as string) === 'TEAM' || (subscriptionLevel as string) === 'TEAMS'
        ? SubscriptionLevel.Event
        : subscriptionLevel,
    subscriptionMinutes,
    subscriptionStatus,
    expiresDate: eventConfig?.expiresDate,
    renewsDate: eventConfig?.renewsDate,
  };
};

/** Check if the Event Is Active */
export const isEventActive = (project: Project): boolean => {
  const {
    canBeActive,
    eventIsActive,
    subscriptionStatus,
    subscriptionId,
    // eventType,
    // eventDate,
    // eventConfig,
    // subscriptionBy,
    // subscriptionLevel,
    // subscriptionMinutes,
  } = getEventConfig(project);
  if (!canBeActive) {
    return false;
  }
  // if you subscribed, or event is active (DB might be wrong, allow this to control it..
  const activeSub = subscriptionStatus === SubscriptionStatus.Active && subscriptionId?.length > 0;
  if (eventIsActive && !activeSub) {
    // console.log(`isEventActive - yes, but we are failing due to SubStatus priority: ${project.id}`, { activeSub, eventType, canBeActive, eventIsActive, eventDate, project });
  }
  return activeSub; // || eventIsActive;
};

/*
 * Allow Pro User to create an Event for their Project
 * - Convert project to eventType & eventDate with Teams/Wedding Package
 */
export const canCreateEvent = (project): boolean => {
  if (!ENABLE_BILLING) {
    return false;
  }
  const canBeActive = canEventTypeMakeActive(project);
  const eventActive = isEventActive(project);
  console.log(`canCreateEvent in dev...`, { canBeActive, eventActive, project });
  return canBeActive && !eventActive;
};

export class ProjectMember {
  userId: string;
  username: string;
  role: PROJECT_MEMBER_ROLE;
  isActive: boolean;
  createdAt?: string;
  avatar?: string;
  projectId?: string;
  projects?: Project[];
  updatedBy?: string;

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

export enum PROJECT_PRIVACY {
  UNLISTED = 'UNLISTED',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

/**
 * Check if this userId is a member or if the project is Public
 * Hide Unlisted and Private if not member
 */
export const isViewableByUserId = (userId: string, project: Project): boolean =>
  (project && project.privacy === PROJECT_PRIVACY.PUBLIC) || checkIsMember(userId, project);

export interface ProjectConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any; // add other config props as needed
  showTrendingStacks?: boolean;
  // TODO: [MVP-1276] move this to Project root model so we can filter in queries for discover
  showRecentStacks?: boolean;
  /** @deprecated use Project.isModerated */
  moderateStacks?: boolean; // producerMustModerateStacks
  /** @deprecated use Project.isModerated */
  moderateClips?: boolean; // producerMustModerateClips
}

export interface EventConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any; // add other config props as needed
  dteExpires?: string;
  showRecentStacks?: boolean;
}

export class Project implements ShareableItem {
  id: string;
  title: string = 'Loading...';
  owner: string;
  mission: string; // short description
  description: string;
  logoUrl: string;
  hero: string; // upload image
  featured?: number;
  views: number = 0;
  votes: number = 0;
  shares: number = 0;
  channel: string;
  created: string;
  configJsonUrl?: string;
  topics?: Array<string>;
  emotions?: Array<string>;
  tags?: Array<string>;
  template?: string;
  streams?: Array<string>;
  shareUrl: string = null;
  members: ProjectMember[] = [];
  privacy?: PROJECT_PRIVACY;
  config?: Stringified<ProjectConfig> | ProjectConfig;
  allowPublicPublish?: boolean;
  type = ITEM_TYPE.Projects;
  // subscription config

  // plug in to events
  eventDate?: string;
  eventType?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventConfig?: any; // AWSJSON
  eventIsActive?: boolean;
  subscriptionLevel?: SubscriptionLevel;
  subscriptionStatus?: SubscriptionStatus; // 0 or 1
  subscriptionMinutes?: number; // 'Int'
  subscriptionId?: string;
  subscriptionBy?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscriptions: any[] = [];

  error?: string;
  /** @deprecated use privacy */
  private?: boolean;

  /**
   * clips and stacks must be moderated before public
   * @note when get recent/trending by projects, have to filter Stacks.isApproved for Projects.isModerated
   */
  isModerated?: boolean;

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}
