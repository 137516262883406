/** @format */

import { createSelector, createFeatureSelector } from '@ngrx/store';
// import { State as AppState } from '@store/reducers';
import { State, featureKey } from './billing.reducer';
import {
  ChargebeeSubscription,
  SUB_LEVEL_TRANSLATE_KEYS,
  SubscriptionLevel,
  SubscriptionStatus,
} from '@billing/shared/billing.model';
import { ENABLE_BILLING_MINUTES, ENABLE_BILLING } from '@app/app.config';
import _memoize from 'lodash/memoize';
import { selectMyProjects, selectProjectEntities } from '@store/selectors/projects.selectors';
import { isEventActive } from '@projects/shared/project.model';
import { environment } from 'src/environments/environment';

export interface SubscriptionViewModel {
  isActive: SubscriptionStatus;
  level: SubscriptionLevel;
  name?: string; // translateKey
  label?: string; // translateKey
  proSubId?: string;
  eventsSubIds?: string[];
  /** @deprecated no root id, rather Pro or events */
  id?: string;
}

export const selectBilling = createFeatureSelector<State>(featureKey);
// export const selectBillingInfo = (state: State) => state.billingInfo;

export const selectPlans = createSelector(selectBilling, (state: State) => ({
  plans: state.plans,
  addons: state.addons,
}));

export const selectPlansLoaded = createSelector(selectPlans, ({ plans }) => plans?.length > 0);
export const selectBillingLoaded = createSelector(selectBilling, (state: State) => state.loaded);
export const selectSubscriptionIsActive = createSelector(selectBilling, (state: State) => state.status);

/** If the subscription is NOT active, then the Level must be default, unless it's loading */
export const selectSubscriptionLevel = createSelector(
  selectBilling,
  selectSubscriptionIsActive,
  (state: State, isActive: SubscriptionStatus) =>
    !state.loaded || state.level === SubscriptionLevel.Loading
      ? SubscriptionLevel.Loading
      : isActive && Object.values(SubscriptionLevel).includes(state.level)
      ? state.level
      : SubscriptionLevel.Unsubscribed
);

/** helper function to get the SubscriptionLevel translate keys as { name, label } */
const getLevelTranslateKeys = (level) => {
  switch (level) {
    case SubscriptionLevel.Loading:
    case SubscriptionLevel.Crew:
    case SubscriptionLevel.Trial:
    case SubscriptionLevel.Pro:
    case SubscriptionLevel.Event:
    case SubscriptionLevel.Wedding:
    case SubscriptionLevel.Sponsored:
    case SubscriptionLevel.Unsubscribed:
      return SUB_LEVEL_TRANSLATE_KEYS[level];
    default:
      console.warn('Unknown Level?', level);
      return SUB_LEVEL_TRANSLATE_KEYS[SubscriptionLevel.Unknown];
  }
};

export const selectSubscriptionLevelNameTranslateKey = createSelector(
  selectSubscriptionLevel,
  (level) => getLevelTranslateKeys(level).name
);
export const selectSubscriptionLevelLabelTranslateKey = createSelector(
  selectSubscriptionLevel,
  (level) => getLevelTranslateKeys(level).label
);

export const selectSubscriptions = createSelector(selectBilling, (state: State) => state.chargebeeSubscriptions);
export const selectProSubscriptionId = createSelector(selectBilling, (state: State) => state.proSubId);
export const selectEventSubscriptionIds = createSelector(selectBilling, (state: State) => state.eventSubIds);

export const selectEventSubscriptions = createSelector(
  selectSubscriptions,
  selectEventSubscriptionIds,
  (subscriptions: ChargebeeSubscription[], eventSubIds: string[]) =>
    eventSubIds.map((id) => subscriptions.find((sub) => sub?.id === id))
);

export const selectEventSubscriptionProjects = createSelector(
  selectEventSubscriptions,
  selectProjectEntities,
  (subscriptions: ChargebeeSubscription[], projects) => subscriptions.map((sub) => projects[sub.cf_project_id])
);

/** get the combined viewmodel state */
export const selectSubscriptionStatus = createSelector(
  selectSubscriptionIsActive,
  selectSubscriptionLevel,
  selectSubscriptionLevelNameTranslateKey,
  selectSubscriptionLevelLabelTranslateKey,
  selectProSubscriptionId,
  selectEventSubscriptionIds,
  // selectSubscriptions,
  (
    isActive: SubscriptionStatus,
    level: SubscriptionLevel,
    name: string,
    label: string,
    proSubId,
    eventsSubIds
    // subscriptions: ChargebeeSubscription[],
  ) => ({
    isActive,
    level,
    name,
    label,
    proSubId,
    eventsSubIds,
    // subscriptions
  })
);

export const selectCustomer = createSelector(selectBilling, (state: State) => state.chargebeeCustomer);

/**
 * @todo finish implementation & ENABLE_BILLING_MINUTES
 * - Present User with AddMinutes AddOn if they are out
 * - Need UI to display minutes used in current month
 */
export const selectMinutes = createSelector(selectBilling, (state: State) => state.minutes || 0);

export const selectIsPro = createSelector(
  selectSubscriptionIsActive,
  selectSubscriptionLevel,
  (isActive: SubscriptionStatus, level: SubscriptionLevel) => isActive && level === SubscriptionLevel.Pro
);

export const selectSubscriptionSummary = createSelector(
  selectSubscriptionIsActive,
  selectSubscriptionLevel,
  selectMinutes,
  (status: SubscriptionStatus, level: SubscriptionLevel, minutes) => ({
    status,
    level,
    minutes,
  })
);

/**
 * If you are Active Pro & have minutes, or minutes not enabled
 * or IsActive Crew/Guest on Project with Active Event
 * MVP-1144
 */
export const selectCanCapture = createSelector(
  selectSubscriptionIsActive,
  selectSubscriptionLevel,
  selectMinutes,
  (isActive: SubscriptionStatus, level: SubscriptionLevel, minutes: number) => {
    !environment.production &&
      console.log(`selectCanCapture`, {
        isActive,
        minutesTest: !ENABLE_BILLING_MINUTES || minutes > 0,
        levelTest: level === SubscriptionLevel.Pro || level === SubscriptionLevel.Event || level === SubscriptionLevel.Crew,
        level,
      });
    return !ENABLE_BILLING || (
      isActive &&
      (!ENABLE_BILLING_MINUTES || minutes > 0) &&
      (level === SubscriptionLevel.Pro || level === SubscriptionLevel.Event || level === SubscriptionLevel.Crew)
    );
  }
);

/**
 * If you are Active Pro & have minutes, or minutes not enabled
 * or IsActive Crew/Guest on Project with Active Event
 * MVP-1144
 */
export const selectCanCaptureToProject = _memoize((id: string) =>
  createSelector(selectCanCapture, selectMyProjects, (canCapture, myProjects) => {
    const proj = myProjects.find((p) => p?.id === id);

    !environment.production &&
      console.log(`selectCanCaptureToProject`, {
        id,
        canCapture,
        proj,
        isEventActive: isEventActive(proj),
      });

    return canCapture || isEventActive(proj);
  })
);
