/** @format */

import { Component, Input, ViewChild } from '@angular/core';
import { BaseModalComponent } from '@shared/components/ui/modal/base-modal.component';

@Component({
  selector: 'fs-qr-modal',
  templateUrl: './qr-modal.component.html',
  styleUrls: ['./qr-modal.component.scss'],
})
export class QrModalComponent {
  @Input() shareUrl = '123';

  @ViewChild('modal', { static: false, read: BaseModalComponent })
  modal: BaseModalComponent;

  dismiss(data = {}) {
    this.modal.dismiss(data);
    // const modal = await this.ModalCtrl.getTop();
    // if (modal) {
    //   modal.dismiss(data);
    // }
  }
}
