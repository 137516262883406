<ion-header>
  <app-top-navbar title="">
    <!-- SLOT: anything added here will go below the ion-navbar -->
  </app-top-navbar>
</ion-header>

<!-- 
  acceptInvite()

  "ACCEPT_TITLE": "Join Wedding Project",
  "ACCEPT_BODY_1": "Joining this project will allow you to capture video clips and help tell shared stories by arranging content into Filmstacks.",
  "ACCEPT_BODY_2": "Once you've accepted the invite, we'll take you on tour on how to use Filmstacker.",
  "ACCEPT_ACTION": "Accept Invite",
  "ACCEPT_SUCCESS": "Successfully joined the project.",
 -->
<ng-template #ActionButtonsRef>
  <p *ngIf="isTokenExpired" class="bold">{{ 'ONBOARDING.COMMON.INVITE.EXPIRED' | translate }}</p>
  <ion-button [disabled]="!tokenId || isTokenExpired" (click)="acceptInvite()">
    {{ translatePath + 'ACCEPT_ACTION' | translate }} 
  </ion-button>
  <div *ngIf="!tokenId">
    {{ translatePath + 'ACCEPT_NO_TOKEN' | translate }} 
  </div>
  <!-- <ion-button *ngIf="project?.id" [routerLink]="['stack/discover/project/', project.id]">
    {{ translatePath + 'ACCEPT_ACTION_2' | translate }} 
  </ion-button> -->
</ng-template>

<ion-content>
  <div class="content-background d-flex ion-padding"
      [style.background-image]="'url(' + backgroundUrl + ')'"
      [style.background-position]="backgroundPosition">
    <div class="container ion-padding">
      <div class="content-wrap ion-padding">
        <h1>
          {{ translatePath + 'ACCEPT_TITLE' | translate }}
        </h1>
        <h3 *ngIf="project" class="constrained bold">
          {{ project.title }} 
          <!-- <div>
            {{ project.description }} 
          </div> -->
        </h3>
        <div *ngIf="(isLoggedIn$ | async) === true" class="action-buttons ion-margin-top">
          <ng-template [ngTemplateOutlet]="ActionButtonsRef"></ng-template>
        </div>
      </div>
    </div>
  </div>
  <section class="how-it-works light">
    <h3 *ngIf="inviteMessage">{{ inviteMessage }}</h3>
    <!-- <p *ngIf="inviteMessage">{{ inviteMessage }}</p> -->

    <ul class="ion-padding-bottom">
      <li>{{ translatePath + 'ACCEPT_BODY_1' | translate }} </li>
      <li>{{ translatePath + 'ACCEPT_BODY_2' | translate }} </li>
    </ul>

    <div class="action-buttons ion-text-center ion-padding-top">
      <ng-container *ngIf="(isLoggedIn$ | async) === true; else notLoggedIn">
        <ng-template [ngTemplateOutlet]="ActionButtonsRef"></ng-template>
      </ng-container>
      <ng-template #notLoggedIn>
        <div class="container skinny-limit-width">
          <h2>{{ 'AUTH.TOPNAV_HAS_ACCOUNT' | translate }}</h2>
          <fs-login-form [routeOnSuccess]="returnUrl"></fs-login-form>
        </div>
        <div class="container ion-padding-top">
          <h2>{{ 'AUTH.TOPNAV_NO_ACCOUNT' | translate }}</h2>
          <ion-button fill="outline" (click)="signUp()">
            {{ 'AUTH.SIGNUP.ACTION' | translate }} 
          </ion-button> 
        </div>
      </ng-template>
    </div>
    <!-- <app-onboarding-how-it-works [routeId]="routeId"></app-onboarding-how-it-works> -->
  </section>

  <!-- <app-onboarding-section-get-started [routeId]="routeId" [actionUrl]="actionUrl"></app-onboarding-section-get-started> -->

  <div class="ion-margin-top">
    <app-footer></app-footer>
  </div>
</ion-content>
