/** @format */

import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ITEM_TYPE } from '@shared/models/layout.model';
import * as types from './index';

@Component({
  selector: 'fs-add-new-item-button',
  template: `<ng-template #slide></ng-template>`,
  styleUrls: ['./fs-add-new-item-button.component.scss'],
})
export class FsAddNewItemButtonComponent implements OnInit {
  @Input() itemType: ITEM_TYPE;
  @Input() isEvent: boolean = false;

  @ViewChild('slide', { static: true, read: ViewContainerRef })
  private slide: ViewContainerRef;

  ngOnInit(): void {
    if (this.itemType) {
      const className = this.getComponentName(this.itemType);

      if (!types[className]) {
        console.warn(`[fs-add-new-item-button] Type '${className}' NOT FOUND: `, types);
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const componentRef: any = this.slide.createComponent(types[className]);
      if (className === 'AddNewProjectButtonComponent') {
        console.log('className', className);
        componentRef.instance.isEvent = this.isEvent;
      }
    }
  }

  getComponentName(name: string) {
    return `AddNew${name.charAt(0).toUpperCase()}${name.toLowerCase().slice(1, -1)}ButtonComponent`;
  }
}
