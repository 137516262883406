<!-- @format -->

<ion-header>
  <ng-container *ngIf="stack$ | async as stack; else noStack">
    <app-top-navbar 
      [title]="stack.title === '' ? ('EDITOR.START_STACK' | translate) : stack.title"
      [backButtonReturnUrl]="returnUrl"
      [drawerEnabled]="true"
      [drawerOpenOnLoad]="drawerOpenOnLoad && !stackId"
      [showtitleActionButton]="!stackId"
      [drawerExpandEvent]="topDrawerExpand$ | async"
      [drawerCloseEvent]="topDrawerClose$ | async"
      (drawerExpand)="draftDrawerExpanded($event)"
      (drawerClose)="draftDrawerExpanded($event)"
    ></app-top-navbar>

    <div *ngIf="!isPreviewOpen" class="top-action-btns">
      <!-- 2023-17 add description -->
      <div *ngIf="stack.description else defaultDesctext" class="stack-description">
        {{ stack.description }}
      </div>
      <!-- 
        if isCollab && isMine
          Add your Clips to this Story!
          EDITOR.INSTRUCT_MINE

        elseif isCollab !isMine
          Add your clips to this story and help find the best order. 
          Only the owner can publish. 

        else if isMine
          if !hasClips -> Add your Clips to this Story!
          EDITOR.INSTRUCT_MINE

        else
          blank except for stack?.description
      -->
      <ng-template #defaultDesctext>
        <div class="stack-description default-text">
          <div *ngIf="isCollaborative else notCollabDescText">
            <ng-container *ngIf="isMine else notMineCollabDescText">
              {{ 'EDITOR.INSTRUCT_MINE' | translate }}
            </ng-container>
            <ng-template #notMineCollabDescText>
              <!-- Add your clips to this story and help find the best order. 
          Only the owner can publish.  -->
              {{ 'EDITOR.ADD_TO_STORY_COLLAB' | translate }}
            </ng-template>
          </div>
          
          <ng-template #notCollabDescText>
            <div *ngIf="isMine">
              <div *ngIf="hasStackClips else notCollabNoClipsText">
                <!-- Continue to change poster or description, finally Publish. -->
                <!-- Add & order clips, before continuing to title and poster -->
                {{ 'EDITOR.INSTRUCT_MINE' | translate }}
              </div>
              <ng-template #notCollabNoClipsText>
                {{ 'EDITOR.ADD_TO_STORY' | translate }}
              </ng-template>
            </div>
            <div *ngIf="!isMine">
              {{ 'STACK.DRAFT_NOT_COLLAB_DESC' | translate }}
            </div>
          </ng-template>
        
        </div>
      </ng-template>

      <!-- <ion-buttons slot="end"> -->
        <!-- at xs break point, only publish icon -->
        <!-- disabled= || !stackFormDetails || !stackFormDetails.createForm || !stackFormDetails.createForm.valid -->
  
        <ng-container *ngIf="stackId else noStackBtn">
          <ng-container *ngIf="!isPreviewOpen">
            <!-- 2022-11-02 changed to be the same for both.. -->
            <!-- 2023-07-27 don't allow save  -->
            <ion-button
              *ngIf="isMine || (isCollaborative && canCollaborate && !isPublished) else startNewStack"
              fill="outline"
              class="icon-padding"
              [disabled]="isSaving || !hasStackClips || (!isMine && !canCollaborate)"
              (click)="saveStack()"
            >
              <ion-spinner *ngIf="isSaving" slot="icon-only" name="crescent"></ion-spinner>
              <ion-icon *ngIf="!isSaving" slot="icon-only" name="save-outline"></ion-icon>
            </ion-button>
            <ng-template #startNewStack>
              <ion-button *ngIf="stack.dtePublished" [routerLink]="[watchRoute, stack.projectId, stack.stackId]" color="primary" fill="clear">
                {{ 'COMMON.WATCH' | translate }}
                <ion-icon slot="start" name="play"></ion-icon>
              </ion-button>

              <!-- remove ReStack button from top MVP-1342 -->
              <!-- <ion-button *ngIf="!openInvite && hasStackClips"
                (click)="addAllClipsToStack()" 
                color="primary" fill="outline">
                  {{ 'STACK.ACTIONS.RESTACK_NEW_DRAFT' | translate }}
              </ion-button> -->
              <ion-button *ngIf="!openInvite && !hasStackClips"
                (click)="newDraft()" 
                color="primary" fill="outline">
                  {{ 'EDITOR.CREATE_NEW_STACK' | translate }}
                  <!-- <ion-icon *ngIf="!isSaving" slot="end" name="save-outline"></ion-icon> -->
              </ion-button>
              <ion-button *ngIf="stack.isCollaborative && !isPublished && openInvite"
                (click)="captureDevice()" 
                color="primary">
                  {{ 'FILE_UPLOAD.JOIN_CREW_UPLOAD_CLIPS' | translate }}
                  <ion-icon slot="end" name="add"></ion-icon>
              </ion-button>
            </ng-template>

            <!-- removed: disabled="... || hasStackClipTooLong" -->
            <!-- removed: disabled="... || hasStackClipsTranscoding" (allow to save form data while transcoding) -->
            <ion-button *ngIf="isMine else notMineContinueButton"
              [disabled]="isPublishing || !hasStackClips"
              (click)="actionClick($event)"
            >
              <ion-spinner *ngIf="isPublishing" slot="start" name="crescent"></ion-spinner>
              {{ 'COMMON.CONTINUE' | translate }}
              <ion-icon slot="end" name="send"></ion-icon>
              <!-- <span class="ion-hide-sm-down">{{ 'COMMON.PUBLISH' | translate }}</span>
              <ion-icon class="ion-hide-sm-down" slot="end" name="send"></ion-icon>
              <ion-icon class="ion-hide-sm-up" slot="icon-only" name="send"></ion-icon> -->
            </ion-button>

            <ng-template #notMineContinueButton>
              <ion-button *ngIf="isCollaborative && canCollaborate && !isPublished"
                (click)="captureDevice()"
              >
                {{ 'EDITOR.FAB.ADD_CLIPS' | translate }}
              </ion-button>
            </ng-template>

            <!-- <ng-container *ngIf="stack.dtePublished">
            </ng-container> -->
          </ng-container>
        </ng-container>
  
        <ng-template #noStackBtn>
          <!-- replaced with topnav[showtitleActionButton]="!stackId" -->
          <!-- <ion-button
            fill="outline"
            color="primary"
            (click)="expandDraftDrawer(true)"
          >
            {{ 'EDITOR.CREATE_NEW_STACK' | translate }}
            <ion-icon slot="start" name="arrow-up"></ion-icon>
          </ion-button> -->
        </ng-template>
      <!-- </ion-buttons> -->
    
    
    </div>

    <div *ngIf="isPreviewOpen" class="close-preview-btn">
      <ion-button fill="outline" color="light" (click)="closePreview()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </div>

  </ng-container>
</ion-header>

<section *ngIf="stack$ | async as stack">
  <div *ngIf="hasStackClips" class="player-wrap" [class.player-wrap--closed]="!isPreviewOpen">
    <app-video-player
      [playerId]="playerId"
      [playlist]="stack.clips"
      [currentIndex]="currentPlaylistIndex$ | async"
      [autoplay]="isPreviewOpen"
      [fullscreen]="false"
      [handlePlaylistDone]="false"
      (playerHeight)="onPlayerHeight($event)"
      (videoDone)="onPlayerEnded($event)"
      (videoPlaying)="isVideoPlaying = $event"
      (playlistDone)="onPlaylistDone($event)"
    ></app-video-player>
  </div>
  
</section>

<ion-content #content>
  <div class="col-md-8 offset-md-2 col-xl-6 offset-xl-3">
    <ng-container *ngIf="stack$ | async as stack">
      <div *ngIf="stack.stackId else noStackId">

        <app-my-stack 
          [stack]="stack"
          [clips]="stack.clips" 
          [currentIndex]="currentPlaylistIndex$ | async"
          [isPreviewOpen]="isPreviewOpen"
          [isVideoPlaying]="isVideoPlaying"
          [disableReorder]="false"
          [playerId]="playerId"
          [isSaving]="isSaving"
          [isMine]="isMine"
          [userId]="userId$ | async"
          [isCollaborative]="!!stack?.isCollaborative"
          [canCollaborate]="canCollaborate"
          [openInvite]="openInvite"
          (playClipIndex)="onPlayClipIndex($event)"
          (pauseClipIndex)="onPauseClipIndex($event)"
          (closePreviewPlayer)="isPreviewOpen = false"
          (continuePublish)="continuePublish($event)"
          (saveStack)="saveStack()"
          (newStack)="newDraft()"
          (reStack)="restack()"
          (acceptInvite)="captureDevice()"
          (addClips)="captureDevice()"
        ></app-my-stack>

        <!-- done in my-stack...
        <div *ngIf="isMine && !hasStackClips && !stack?.isCollaborative && !stack.dtePublished" class="ion-padding skinny-limit-width">
          <h3>{{ 'STACK.DRAFT_INVITE' | translate }}</h3>
          <div class="ion-text-center">
            <fs-button buttonStyle="outline" (click)="inviteCrew($event)">
              {{ 'COMMON.LEARN_MORE' | translate }}
            </fs-button>
          </div>
        </div> -->

        <!-- <div class="tab-bottom-spacer"></div> -->

        <app-capture-clip-button
          [projectId]="stack.projectId"
          [startUpload]="captureClipEvent"
          (uploadSuccess)="captureSuccess($event)"
        ></app-capture-clip-button>

        <!-- disabled the tutorial and opening fab instead MVP-1107
        <fs-tutorial-tour
          *ngIf="isTooltipShown"
          [placements]="placements"
          [backdropDismissal]="true"
          (tourCancel)="([])"
          (tourComplete)="([])"
        ></fs-tutorial-tour> -->

      </div>
      <ng-template #noStackId>
        <div class="content-no-stack ion-padding-horizontal ion-margin-horizontal">
          <h1>
            {{ 'EDITOR.START_STACK' | translate }} 
          </h1>
          <h3> {{ 'EDITOR.EDITOR_DEFAULT_CONTENT' | translate }} </h3>
        </div>
      </ng-template>
    </ng-container>
    
    <ng-template #noStack>
      <p class="ion-text-center">
        {{ 'COMMON.LOADING' | translate }}
      </p>
    </ng-template>

    
    <div *ngIf="!hasMyClips && canCollaborate" class="ion-text-center ion-margin ion-padding-top">
      <p *ngIf="(isLoggedIn$ | async) === true else notAuthedDiscoverText">
        {{ 'EDITOR.DISCOVER_CLIPS_INTRO' | translate }}
        <ion-icon name="add-circle-outline"></ion-icon>
      </p>
      <ng-template #notAuthedDiscoverText>
        <p>
          {{ 'EDITOR.DISCOVER_CLIPS_INTRO_SIGNUP' | translate }}
        <ion-icon name="add-circle-outline"></ion-icon>
        </p>
      </ng-template>
      <fs-button buttonStyle="outline" (click)="routeDiscover()">
        {{ 'EDITOR.ADD_CLIPS_FROM_DISCOVER' | translate }}
      </fs-button>
    </div>

    <div *ngIf="isMine || canCollaborate" class="ion-text-center ion-margin">
      <fs-button buttonStyle="clear" [routerLink]="projectUrl">
        {{ 'PROJECT.SETTINGS.BACK_TO_PROJECT' | translate }}
      </fs-button>
    </div>
    <div class="tab-bottom-spacer"></div>
  </div>

</ion-content>

<fs-tab-fab-container>
  <ion-fab *ngIf="isTabFabShown" class="tab-fab" #tabFab>
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <!-- 
        Allow NEW_DRAFT if there’s a Stack AND hasClips AND ( it’s mine OR i can't collaborate) 
        or, not mine & not canCollab
      -->
      <ion-chip *ngIf="(stack$ | async)?.stackId && ((hasStackClips && (isMine || !canCollaborate) && !openInvite) || (!isMine && !(isCollaborative && canCollaborate)))" 
        outline="true" (click)="newDraft()">
        <ion-label>{{ 'EDITOR.FAB.NEW_DRAFT' | translate }}</ion-label>
      </ion-chip>
      <!-- remove these items from fab to simplify
      <ion-chip outline="true" (click)="routeStudio()">
        <ion-label>{{ 'EDITOR.FAB.STUDIO' | translate }}</ion-label>
      </ion-chip>
      <ion-chip *ngIf="stack && stack.projectId" outline="true" (click)="routeProject()">
        <ion-label>{{ 'COMMON.PROJECT' | translate }}</ion-label>
      </ion-chip> -->
      <ion-chip outline="true" (click)="routeDiscover()">
        <ion-label>{{ 'EDITOR.ADD_CLIPS_FROM_DISCOVER' | translate }}</ion-label>
      </ion-chip>
      
      <ion-chip *ngIf="showYoutubeFab" outline="true" (click)="captureYoutube($event)">
        <ion-label>{{ 'EDITOR.FAB.ONLINE' | translate }}</ion-label>
      </ion-chip>
      <ng-container *ngIf="isMine">
        <ion-chip *ngIf="isCollaborative else notCollaborative" outline="true" (click)="removeCrew($event)">
          <ion-label>{{ 'STACK.ACTIONS.REMOVE_CREW' | translate }}</ion-label>
        </ion-chip>
        <ng-template #notCollaborative>
          <ion-chip outline="true" (click)="inviteCrew($event)">
            <ion-label>{{ 'STACK.ACTIONS.INVITE_CREW' | translate }}</ion-label>
          </ion-chip>
        </ng-template>
      </ng-container>

      <ion-chip *ngIf="isMine || (isCollaborative && canCollaborate) || openInvite" outline="true" (click)="captureDevice()">
        <ion-label>{{ 'EDITOR.FAB.ADD_CLIPS' | translate }}</ion-label>
        <!-- <ion-label>{{ 'EDITOR.FAB.MY_DEVICE' | translate }}</ion-label> -->
      </ion-chip>
    </ion-fab-list>
  </ion-fab>
</fs-tab-fab-container>
