/** @format */

import _memoize from 'lodash/memoize';
import { createSelector, createFeatureSelector } from '@ngrx/store';
// import { State as AppState } from '@store/reducers';
import { State, featureKey, selectEntities } from '../reducers/lists.reducers';
import { selectClipEntities } from '@store/selectors/clips.selectors';
import { selectStackEntities } from '@store/selectors/stacks.selectors';
import { selectProjectEntities } from '@store/selectors/projects.selectors';

const DEBUG_LOGS = false;

export { List } from '../reducers/lists.reducers';

export const selectListsState = createFeatureSelector<State>(featureKey);

export const selectListEntities = createSelector(selectListsState, selectEntities);

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * MEMOIZATION
 * In contrast to a normal NgRx selector where the selector is shared across multiple components,
 * we now have a new instance of the selector every time the selector factory is invoked.
 * This has the effect that we lose the memoization benefits of the selector.
 *
 * For most cases this is fine and you won't notice the difference. But when the selector has to so expensive work,
 * you can add a memoization layer on top as a countermeasure.
 *
 * To accomplish this, we must bring our own memoization method to the table, for example lodash.memoize.
 * After this, we can simply wrap the selector inside of the memoize method.
 * Note that this is a simple example and that you probably don't need to memoize an entity lookup.
 *
 * By doing this, consuming the selector is no different than before.
 *
 * It's important to keep in mind that this builds up an in-memory cache,
 * so that's why it's better that the added entry is disposed of when it's possible.
 *
 * https://lodash.com/docs/4.17.15#memoize
 * Note: The cache is exposed as the cache property on the memoized function.
 * Its creation may be customized by replacing the _.memoize.Cache constructor
 * with one whose instances implement the Map
 * method interface of clear, delete, get, has, and set.
 *
 * @dev This will likely be the solution for Lists items
 *
 * Usage:
 * class MyComponent {
 *  list$ = this.store.select(lists.selectList('listId'));
 * }
 */
export const selectList = _memoize((id: string) => createSelector(selectListEntities, (entities) => entities[id]));

/**
 * Select ListItems By Type
 *
 * USAGE:
 * this.store.select(selectStackListItems(), { id: 'list-id' })
 */
export const selectStackListItems = _memoize((id: string) =>
  createSelector(selectList(id), selectStackEntities, (list, entities) =>
    list && Array.isArray(list.itemIds) ? list.itemIds.map((d) => entities[d]) : undefined
  )
);
/**
 * Get the project Drafts + Mine
 * @param id
 */
export const selectCollabStackListItems = _memoize((id: string) =>
  createSelector(selectListEntities, selectStackEntities, (list, entities) =>
    list && list[id]
      ? list[id].itemIds
          .map((d) => entities[d])
          .filter((stack) => stack && !stack.dtePublished && stack.isCollaborative > 0)
      : undefined
  )
);

export const selectProjectListItems = _memoize((id: string) =>
  createSelector(selectList(id), selectProjectEntities, (list, entities) =>
    list && Array.isArray(list.itemIds) ? list.itemIds.map((d) => entities[d]) : undefined
  )
);

export const selectClipListItems = _memoize((id: string) =>
  createSelector(
    selectList(id),
    selectClipEntities,
    // (list, entities) => list && Array.isArray(list.itemIds) ? list.itemIds.map((d) => entities[d]) : undefined
    (list, entities) => {
      const results = list && Array.isArray(list.itemIds) ? list.itemIds.map((d) => entities[d]) : undefined;
      DEBUG_LOGS && console.log({ results, list, entities });
      return results;
    }
    // (listEntities, entities) => listEntities[id] && Array.isArray(listEntities[id].itemIds) ? listEntities[id].itemIds.map((d) => entities[d]) : undefined
  )
);

/**
 * select the Stack Drafts
 */
export const selectStackDraftListItems = _memoize((id: string) =>
  createSelector(selectListEntities, selectStackEntities, (list, entities) =>
    list && list[id]
      ? list[id].itemIds
          .map((d) => entities[d])
          .filter((stack) => stack && !stack.dtePublished && !stack.isCollaborative)
      : undefined
  )
);

/**
 * MVP-970 ngrx deprecated selectorWithProps
 * The currently recommended approach to this is using a factory function
 * ... but this is _not_ memoized
 */

/**
 * usage:
 * this.store.select(entityById(someID))
 */
export const entityById = (id: string) => createSelector(selectListEntities, (entities) => entities[id]);

/**
 * Usage:
 * this.store.select(entitiesByIDs(arrayOfIDs)
 */
export const entitiesByIDs = (ids = []) =>
  createSelector(selectListEntities, (entities) => ids.map((id) => entities[id]));

/**
 * However, a type safe alternative is demonstrated in RFC 2980. Applied to the answer from @jordan-gray:
 * Usage:
 * this.store.select(selectEntity({ id: myId }));
 */
export const selectEntity = (props: { id: string }) =>
  createSelector(selectListEntities, (entities) => entities[props.id]);

// example:
// export const hasRight = (rightCode: RightCode) => createSelector(selectUser, (user) => {
//   return user?.rights?.indexOf(rightCode) !== -1;
// });
// // you can consume it as
// const canEditClient$ = this.store.select(hasRight(RIGHT_EDIT_CLIENT));
// https://github.com/ngrx/platform/issues/2980#issuecomment-819551245

// good example: https://github.com/ngrx/platform/issues/2980#issuecomment-856617283
