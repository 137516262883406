/**
 * 2022-06-29 jd migrated functionality from projects.reducers MVP-970
 * @format
 */
/* eslint-disable arrow-body-style */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Project, checkIsMember, getIsMemberRole } from '@projects/shared/project.model';
import * as ProjectActions from '@store/actions/projects.actions';
import * as MemberActions from '@store/actions/members.actions';
import * as ResetActions from '@store/actions/reset.actions';
import * as UserActions from '@store/actions/user.actions';
import * as BillingActions from '@billing/store/billing.actions';
// import { selectUserState } from '../selectors/user.selectors';
// import keyBy from 'lodash/keyBy';
import { Utils } from '@shared/utils';
import { environment } from 'src/environments/environment';
import { getTopPermission, ProjectMember } from '@members/shared/project-member.model';
import { getEventSubscriptions, SubscriptionLevel, SubscriptionStatus } from '@billing/shared/billing.model';

const DEBUG_LOGS = false;
const PAGE = '[ProjectsStore]';

export const featureKey = 'projects';

export enum ProjectGroup {
  Featured = 'FEATURED',
  Recent = 'RECENT',
  Mine = 'MINE',
  Other = 'OTHER',
}

export interface State extends EntityState<Project> {
  // additional entity state properties
  selectedId: string | null; // activeProject
  loaded: string[];
  loading: string[];
  // featured: string[]; // ids of FeaturedProjects - instead, just use the entities, filtered
  mine: string[]; // ids of Owner and Crew
  /** @deprecated this seems wrong, we should be using the userStore! */
  userId: string;
  nextTokenFeatured: string | null;
  nextTokenMine: string | null;
  nextTokenOther: string | null;
  nextTokens: { [id: string]: string | null };
  // recent: string[]; // moved to environ to save state
}

export const adapter: EntityAdapter<Project> = createEntityAdapter<Project>({
  selectId: (project) => project.id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: [],
  loading: [],
  ids: [],
  entities: {},
  selectedId: null,
  // featured: [],
  mine: [],
  userId: '',
  nextTokenFeatured: null,
  nextTokenMine: null,
  nextTokenOther: null,
  nextTokens: {},
  // recent: [],
});

/** Return new array with item removed */
const newFilteredArray = (arr, str: string) => arr.filter((item) => item !== str);
/** Retrurn new array unique from array */
const newFilteredArrayFromArray = (arr: string[], arrStr: string[]) =>
  arrStr.length < 1 ? [...arr] : arr.filter((item) => arrStr.indexOf(item) < 0);

const projectReducer = createReducer(
  initialState,

  on(ProjectActions.reset, ResetActions.resetStore, ResetActions.resetStoreThenReboot, () => ({ ...initialState })),
  /**
   * @todo validate migration of MINE projects to -v2 store
   */
  on(ResetActions.resetStoreOnLogout, (state) => ({
    ...state,
    loaded: [...newFilteredArray(state.loaded || [], ProjectGroup.Mine)],
    selectedId: null,
    mine: [],
    userId: '',
    nextTokenMine: null,
  })),
  /**
   * @todo instead use Effect to => LOAD_SUCCESS
   * TODO: Effect verifying enitities exist in projects store`);
   */
  on(ProjectActions.hydrated, (state) => ({
    ...state,
    loaded: state.loaded || [], // should come with hydration
    loading: [],
  })),

  on(UserActions.loginSuccess, (state, { user }) => ({
    ...state,
    userId: user.userId,
  })),
  on(UserActions.logout, (state) => ({
    ...state,
    userId: '',
  })),

  on(
    ProjectActions.selectById,
    // ProjectActions.loadById, // no... this might have been a headache now fixed
    (state, { id }) => ({
      ...state,
      selectedId: id,
    })
  ),
  on(ProjectActions.loadById, (state, { id }) => ({
    ...state,
    loading: newFilteredArray(state.loading, id),
  })),

  on(ProjectActions.select, (state, { project }) => ({
    ...state,
    selectedId: project.id,
  })),

  on(ProjectActions.subUpdate, (state, { project, userId }) => {
    // NOTE: this project might NOT have all the props, just what changed - need to validate we handle all cases!
    // this is an item we are tracking, or check if i am a member or owner
    if (
      project &&
      project.id &&
      ((state.entities[project.id] && state.entities[project.id].id) || checkIsMember(userId, project))
    ) {
      const entity = Object.assign({}, state.entities[project.id]);
      const newMineIds = [];
      for (const prop in project) {
        if (
          project.hasOwnProperty(prop) &&
          (project[prop] || typeof project[prop] === 'boolean' || typeof project[prop] === 'number')
        ) {
          const val = project[prop];
          if (entity[prop] !== val) {
            // !environment.production && console.log(`[subUpdate] changed [${prop}]`, val);
            if (prop === 'members') {
              if (val.length > 0) {
                entity[prop] = val;
                if (entity[prop].some((m) => m.userId === userId && m.isActive)) {
                  !environment.production && console.log(`[subUpdate] changed MINE [${prop}]`, entity[prop]);
                  newMineIds.push(project.id);
                }
              } else {
                // assuming members were not in the query so don't overwrite existing
              }
            } else {
              entity[prop] = val;
            }
          }
        }
      }
      console.log(`[ProjectStore] subUpdate:`, { entity });
      return adapter.upsertOne(entity, {
        ...state,
        mine: [...newMineIds, ...newFilteredArrayFromArray(state.mine, newMineIds)],
      });
    } else {
      // ignoring clips we don't care about.. - we already checked if (myProjectIds.includes(item.id))
      console.log(`ProjectStore subUpdate ignoring:`, project);
      return state;
    }
  }),

  on(MemberActions.subUpdate, (state, { memberProject, userId }) => {
    DEBUG_LOGS && console.log({ memberProject, state, userId });
    const projectId = memberProject && memberProject.projectId;
    // remove from mine if not active.. adding in CoreLogic addmine action dispatch
    const mine =
      memberProject && !memberProject.isActive ? state.mine.filter((id) => id && id !== projectId) : [...state.mine];
    if (projectId && state.entities[projectId] && state.entities[projectId].id) {
      // this is an item we are tracking
      const entity = Object.assign({}, state.entities[projectId]);

      DEBUG_LOGS && console.log(`[Store] Projects subUpdate:`, { memberProject, mine });
      return adapter.upsertOne(
        {
          ...entity,
          members: [...entity.members.filter((p) => p && p.userId !== memberProject.userId), memberProject],
        },
        {
          ...state,
          mine,
        }
      );
    } else {
      // ignoring clips we don't care about.. - this should be handled before the action is sent, no?
      console.log(`subUpdate Members ignoring:`, memberProject);
      return {
        ...state,
        mine,
      };
    }
  }),

  on(ProjectActions.add, ProjectActions.loadByIdSuccess, (state, { project, userId }) => {
    if (!project || !project.id) return state;

    // handle if these should also be added to MINE:
    const newMineIds = [];
    // logic was previously checked in effect before sending this, but now we need to validate due to project-detail loading before user.loggedIn
    // if (userId && userId === state.userId) {
    const myMember: Partial<ProjectMember> =
      userId && Array.isArray(project.members) ? project.members.find((m) => m.userId === userId) : {};
    // if i am no longer active, remove from mine  (myMember && !myMember.isActive)
    // but, we're appending the new, so filter it in both cases
    const currentMine = state.mine.filter((m) => m && m !== project.id);
    if (myMember && myMember.isActive) {
      DEBUG_LOGS && console.log(`loadByIdSuccess verified userId(${userId}) is member (${project.id}) -> add to MINE`); //, { project, members: project.members })
      // newMineIds = currentMine.includes(project.id) ? [] : [project.id];
      newMineIds.push(project.id);
    }
    // DEBUG_LOGS && console.log(`project config check`, { project, config: project.config })
    return adapter.upsertOne(
      {
        ...project,
        config: typeof project.config === 'string' ? Utils.tryParseJSON(project.config) : project.config,
      },
      {
        ...state,
        mine: [...newMineIds, ...currentMine],
        loaded: [...newFilteredArray(state.loaded, project.id), project.id],
        loading: newFilteredArray(state.loading, project.id),
      }
    );
  }),

  on(ProjectActions.loadSuccess, (state, { projects, selected, nextToken, userId, listId = ProjectGroup.Other }) => {
    // added logic from projects.reducer
    // make sure we have uniques in the array (might have dups if there's an owner with member record too)
    const projs = (Array.isArray(projects) ? projects : [])
      .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
      .map((p) => ({
        ...p,
        config: typeof p.config === 'string' ? Utils.tryParseJSON(p.config) : p.config,
      }));

    let newMineIds = [],
      nextTokenFeatured = state.nextTokenFeatured,
      nextTokenMine = state.nextTokenMine,
      nextTokenOther = state.nextTokenOther;

    const nextTokens = state.nextTokens,
      currentMine = Array.isArray(state.mine) ? state.mine : [];

    let currentUserId = state.userId;

    // if (group && (<any>Object).values(Group).includes(group))
    switch (listId) {
      case ProjectGroup.Featured: {
        nextTokenFeatured = nextToken;
        break;
      }
      case ProjectGroup.Mine: {
        if (userId && !currentUserId) {
          currentUserId = userId;
        }
        if (userId && currentUserId !== userId) {
          console.warn(
            `Projects.loadSuccess unexpected action.userId: '${userId}' !== currentUserId: '${currentUserId}'`
          );
          currentUserId = userId;
        }
        newMineIds = projs.filter((proj) => !currentMine.includes(proj.id)).map((proj) => proj.id);
        nextTokenMine = nextToken;
        // add userId to groups too?
        break;
      }
      case ProjectGroup.Other: {
        nextTokenOther = nextToken;
        break;
      }
      default: {
        if (listId) {
          DEBUG_LOGS && console.log(`${PAGE} [loadSuccess] group exists but not in enum '${listId}'`);
          nextTokens[listId] = nextToken;
        } else {
          if (nextToken) {
            DEBUG_LOGS && console.log(`${PAGE} [loadSuccess] no listId but nextToken? '${listId}'`);
            // nextTokens[listId] = nextToken;
          }
        }
      }
    } // end inner switch (listId)

    // handle if these should also be added to MINE:
    if (userId && listId !== ProjectGroup.Mine) {
      if (!currentUserId) {
        currentUserId = userId;
      }
      if (currentUserId === userId) {
        // we didn't already add them, do it
        newMineIds = projs.filter((proj) => !currentMine.includes(proj.id)).map((proj) => proj.id);
      }
    }

    return adapter.upsertMany(projs, {
      ...state,
      loaded: [...newFilteredArray(state.loaded, listId), listId],
      loading: newFilteredArray(state.loading, listId),
      selectedId: selected || state.selectedId,
      mine: [...newMineIds, ...currentMine],
      userId: currentUserId,
      nextTokenFeatured,
      nextTokenMine,
      nextTokenOther,
      nextTokens,
    });
  }),

  on(ProjectActions.loadFail, (state, { error, id }) => {
    console.log(`Project load fail id='${id}'`, error);
    return state;
    // it doesn't make sense to add junk to the store, was trying this to propagate to ProjectDetail, but not working as desired
    // return adapter.addOne(new Project({ id, title: error, error }), state);
  }),

  /**
   * Loading actions from projects-v1.reducer
   */
  on(ProjectActions.loadFeatured, (state, action) => {
    const group = ProjectGroup.Featured;
    // NOTE: if it is NOT an Array, we have an old version of state loaded
    if (Array.isArray(state.loaded) && state.loaded.includes(group) && !state.nextTokenFeatured) {
      DEBUG_LOGS && console.log(`${PAGE} ${action.type} already loaded ${group}.`);
      return state;
    }
    if (Array.isArray(state.loading) && state.loading.includes(group)) {
      DEBUG_LOGS && console.log(`${PAGE} ${action.type} currently loading ${group}`);
      return state;
    }
    return { ...state, loading: [...state.loading, group] };
  }),
  on(ProjectActions.loadMine, (state, action) => {
    const group = ProjectGroup.Mine;
    // NOTE: if it is NOT an Array, we have an old version of state loaded
    if (Array.isArray(state.loaded) && state.loaded.includes(group)) {
      DEBUG_LOGS && console.log(`${PAGE} ${action.type} already loaded ${group}.`);
      return state;
    }
    if (Array.isArray(state.loading) && state.loading.includes(group)) {
      DEBUG_LOGS && console.log(`${PAGE} ${action.type} currently loading ${group}`);
      return state;
    }
    return { ...state, loading: [...state.loading, group] };
  }),
  on(ProjectActions.addMine, (state, action) => {
    const { ids } = action;
    const currentMine = Array.isArray(state.mine) ? state.mine : [];
    const newMineIds = ids.filter((id) => !currentMine.includes(id));
    return { ...state, mine: [...newMineIds, ...currentMine] };
  }),
  on(ProjectActions.removeMine, (state, action) => {
    const { id } = action;
    const currentMine = Array.isArray(state.mine) ? state.mine : [];
    return { ...state, mine: currentMine.filter((d) => d && d !== id) };
  }),

  on(ProjectActions.addMember, (state, { member }) => {
    DEBUG_LOGS && console.log(`DEV: ${PAGE} addMember`, { member });
    if (!member || !member.projectId) {
      return state;
    }
    const projectId = member.projectId;
    const currentProject = state.entities[projectId];
    if (!currentProject || !Array.isArray(currentProject.members)) {
      // fixed in projects.effects.addMemberToProjectExists$
      // will be loaded and then loadByIdSuccess, ignore change here
      // console.warn(`${PAGE} addMember – Missing Project or currentProject.members in Store!!`);
      return state;
    }
    const existsIndex = currentProject.members.findIndex((m) => m.userId === member.userId);

    if (existsIndex > -1) {
      // validate that we are not downgrading this user!
      // if this role !== topPermission & existing is currently Active, skip update
      if (
        member.role !== getTopPermission(member, getIsMemberRole(member?.userId, currentProject)) &&
        currentProject.members[existsIndex].isActive
      ) {
        return state;
      }
    }

    /**
     * @todo refactor - note this is old non-entity code, but i didn't feel like refactoring atm
     */
    return Object.assign({}, state, {
      entities: Object.assign({}, state.entities, {
        [projectId]: Object.assign({}, currentProject, {
          members:
            existsIndex > -1
              ? currentProject.members.map((el) => (el.userId === member.userId ? member : el))
              : [...currentProject.members, member],
        }),
      }),
    });
  }),

  on(ProjectActions.updateMember, (state, { member }) => {
    DEBUG_LOGS && console.log(`DEV: ${PAGE} updateMember`, { member });
    if (!member || !member.projectId) {
      return state;
    }
    const projectId = member.projectId;
    const currentProject = state.entities[projectId];
    if (!currentProject || !Array.isArray(currentProject.members)) {
      // fixed in projects.effects.addMemberToProjectExists$
      // will be loaded and then loadByIdSuccess, ignore change here
      // console.warn(`${PAGE} addMember – Missing Project or currentProject.members in Store!!`);
      return state;
    }
    const existsIndex = currentProject.members.findIndex((m) => m.userId === member.userId);

    /**
     * @todo refactor - note this is old non-entity code, but i didn't feel like refactoring atm
     */
    return Object.assign({}, state, {
      entities: Object.assign({}, state.entities, {
        [projectId]: Object.assign({}, currentProject, {
          members:
            existsIndex > -1
              ? currentProject.members.map((el) => (el.userId === member.userId ? member : el))
              : [...currentProject.members, member],
        }),
      }),
    });
  }),

  on(ProjectActions.setHero, (state, { id, url }) => {
    const project = state.entities[id];
    if (!project || !project.id) {
      console.warn(`Missing projectId: '${id}'? No action taken`);
      return state;
    }
    return adapter.upsertOne({ ...project, hero: url }, state);
  }),

  on(ProjectActions.setSubscriptionEvent, (state, action) => {
    const project = state.entities[action.id];
    if (!project?.id) {
      console.warn(`Missing projectId: '${action.id}'? No action taken`);
      return state;
    }
    // take the vals set and update those, fallback to current value in our local store
    const {
      // id,
      eventConfig = {},
      eventDate = project.eventDate,
      eventIsActive = project.eventIsActive,
      eventType = project.eventType,
      subscriptionId = project.subscriptionId,
      subscriptionLevel = project.subscriptionLevel,
      subscriptionStatus = project.subscriptionStatus,
      subscriptionMinutes = project.subscriptionMinutes,
    } = action;

    return adapter.upsertOne(
      {
        ...project,
        eventConfig,
        eventDate,
        eventIsActive,
        eventType,
        subscriptionId,
        subscriptionLevel: subscriptionLevel as SubscriptionLevel,
        subscriptionStatus,
        subscriptionMinutes,
      },
      state
    );
  }),

  on(BillingActions.loadCustomerSubscriptionsSuccess, (state, { customer, subscriptions }) => {
    if (Array.isArray(subscriptions) && subscriptions.length > 0) {
      const activeProjSubs = getEventSubscriptions(subscriptions); //already done: .filter(sub => isSubscriptionActive(sub) && sub.cf_project_id));

      const projects = [];
      for (const projSub of activeProjSubs) {
        const proj: Partial<Project> = state.entities[projSub.projectId] || { id: projSub.projectId };
        projects.push({
          ...proj,
          eventDate: projSub.eventDate || '',
          eventIsActive: projSub?.id?.length > 0 && projSub.status === SubscriptionStatus.Active,
          // eventType,
          subscriptionId: projSub?.id ?? '',
          subscriptionLevel: projSub.level as SubscriptionLevel,
          subscriptionStatus: SubscriptionStatus.Active,
          // subscriptionMinutes,
          // expires/renews
        });
      }
      DEBUG_LOGS && console.log(`[ProjectStore] billing success:`, { projects, customer, subscriptions });
      return adapter.updateMany(projects, state);
    }
    return state;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return projectReducer(state, action);
}

// selectors

export const { selectEntities, selectIds } = adapter.getSelectors();

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectedId;
export const getMineIds = (state: State) => state.mine;

// moving selectors to separate file...

// export const selectProjectState = createFeatureSelector<State>(featureKey);

// export const selectProjectEntities = createSelector(selectProjectState, selectEntities);
// export const selectProjectIds = createSelector(selectProjectState, selectIds);
// export const selectCurrentProjectId = createSelector(selectProjectState, getSelectedId);

// export const selectCurrentProject = createSelector(
//   selectProjectEntities,
//   selectCurrentProjectId,
//   (projectEntities, projectId) => {
//     return projectEntities[projectId];
//   }
// );

// export const getMyProjects = createSelector(selectProjectEntities, getMineIds, (entities, ids) => {
//   if (!Array.isArray(ids)) return [];
//   return ids.map((id) => entities[id]);
// });

// export const getFeaturedProjects = createSelector(selectProjectEntities, selectProjectIds, (entities, ids) =>
//   // console.log(`${PAGE} getFeaturedProjects entities:`, entities);
//   ids.map((id) => entities[id]).filter((item) => item && item.featured && item.featured > 0)
// );

// export const getFeaturedIds = createSelector(selectProjectEntities, selectProjectIds, (entities, ids) =>
//   ids
//     .map((id) => entities[id])
//     .filter((item) => item && item.featured && item.featured > 0)
//     .map((item) => item.id)
// );

// export const selectCurrentProjectMemberByUser = createSelector(
//   selectCurrentProject,
//   selectUserState,
//   (currentProject, userState) => {
//     return currentProject && Array.isArray(currentProject.members) && currentProject.members.find((member) => member.userId === userState.userId);
//   }
// );

// export const selectIsCurrentProjectAdminByUser = createSelector(
//   selectCurrentProject,
//   selectUserState,
//   (currentProject, userState) => {
//     return isAllowedFor(
//       [PROJECT_MEMBER_ROLE.OWNER, PROJECT_MEMBER_ROLE.PRODUCER, PROJECT_MEMBER_ROLE.EXECUTIVE],
//       currentProject,
//       userState.userId
//     );
//   }
// );

// // helpers

// function isAllowedFor(allowedRoles: PROJECT_MEMBER_ROLE[], project: Project, userId: string): boolean {
//   let isAllowed = false;
//   if (userId && project && Array.isArray(project.members)) {
//     const projectMembersMap = keyBy(
//       project.members.filter((member) => member.isActive),
//       'userId'
//     );
//     isAllowed = allowedRoles.some((r) => projectMembersMap[userId] && projectMembersMap[userId].role === r);
//   }

//   return isAllowed;
// }
