<div *ngIf="(subscriptionVm$ | async) as subscription" class="subscription-detail">
  <h2>{{ 'AUTH.ACCOUNT' | translate }}</h2>
  <ion-list lines="none">
    <ion-item class="sub-summary">
      <ion-label>
        <h3 class="ion-text-wrap">{{ 'AUTH.PLANS.SUBSCRIPTION_LEVEL' | translate }}</h3>
        <p class="ion-text-wrap">
          <fs-subscription-label></fs-subscription-label>
        </p>
      </ion-label>
      <ion-note slot="end" color="light" class="well">{{ subscription.name | translate | uppercase }}</ion-note>
      <ion-button
        slot="end"
        shape="round"
        fill="solid"
        color="primary"
        title="Edit"
        class="icon-btn"
        (click)="subscriptionActionClick(subscription)">
        <ion-icon slot="icon-only" name="create-outline"></ion-icon>
      </ion-button>
    </ion-item>

    <div class="plan-options">
      <div *ngIf="subscription.isActive && subscription?.proSubId else notActiveDetail">
        <!-- <p class="bright">{{ 'BILLING.ALREADY_PRO' | translate }}</p> -->
        <p *ngIf="subscription?.eventsSubIds?.length > 0" class="bright">{{ 'ONBOARDING.COMMON.ACTIVE_DETAIL_WITH_EVENT' | translate }}</p>
        <fs-button size="default" (click)="editSubscription(subscription?.proSubId)">{{ 'AUTH.PLANS.EDIT_SUBSCRIPTION' | translate }}</fs-button>
      </div>
      <ng-template #notActiveDetail>
        <p *ngIf="subscription?.eventsSubIds?.length > 0 else noEventTpl" class="bright">{{ 'ONBOARDING.COMMON.NOT_ACTIVE_DETAIL_WITH_EVENT' | translate }}</p>
        <ng-template #noEventTpl>
          <p class="bright">{{ 'ONBOARDING.COMMON.NOT_ACTIVE_DETAIL' | translate }}</p>
        </ng-template>
        <fs-button size="large" (click)="subscribe()">{{ 'AUTH.PLANS.SUBSCRIBE' | translate }}</fs-button>
      </ng-template>
      <p class="ion-text-wrap" style="line-height: 4em;margin:0;">
        <ion-button class="btn-link-inline with-icon ion-text-wrap" fill="clear" href="https://filmstacker.com/plans-pricing" target="_filmstacker">
          {{ 'AUTH.PLANS.LEARN_MORE' | translate }}
          <ion-icon slot="end" name="open-outline"></ion-icon>
        </ion-button>
      </p>
    </div>

    <!-- 
      Owner: Username (not shown if my event on edit studio)
      Status : Active: Expired
      Expires: Renews: 
      Event Date

      - only show MY events
      -> move to studio page with links for places i can contribute
     -->
    <!-- <ng-container *ngIf="projectsWithEvents$ | async as eventProjects">
    <div *ngIf="eventProjects?.length > 0" class="project-events">
      YOU HAVE PROJECT EVENTS!
    </div>
  </ng-container> -->
    <div *ngIf="myEventProjects$ | async as eventProjects" class="project-events">
      <div *ngIf="eventProjects?.length > 0">
        <h4>{{ 'PROJECT.ACTIVE_EVENTS' | translate }}:</h4>
        <!-- 
          <fs-events-list
            [eventProjects]="eventProjects"
            [subscription]="subscription"
            [showTitle]="false"
            [showOwner]="false"
          ></fs-events-list>
         -->
        <ion-list lines="none">
          <!-- // eventType, canBeActive, 
              eventIsActive, 
              eventDate, 
              eventConfig, 
              subscriptionId,
              subscriptionBy, 
              subscriptionLevel, 
              subscriptionMinutes, 
              subscriptionStatus
          -->
          <ion-item *ngFor="let project of eventProjects" class="project-events__item">
            <ion-label class="ion-text-wrap">
              <ion-button *ngIf="project?.id else noId" [routerLink]="[routeProjectDetail, project.id]"
                fill="clear"
                size="default"
                class="ion-text-wrap ion-text-left">
                {{ project.title }}
              </ion-button>
              <ng-template #noId>
                {{ project.title || '?' }}
              </ng-template>
              <div class="ion-text-capitalize project-subscription-details">
                <div>
                  <span class="italic">{{ 'AUTH.PLANS.SUBSCRIPTION_LEVEL' | translate }}: </span><b>{{ project.eventConfig?.subscriptionLevel ?? project.subscriptionLevel }}</b>
                </div>
                <div>
                  <span class="italic">{{ 'COMMON.DATE' | translate }}: </span>{{ project.eventDate }}
                </div>
              </div>
            </ion-label>
            <div slot="end" class="active-event-icon" title="Active Event">
              <ion-icon name="today-outline" class="no-user"></ion-icon>
            </div>
            <ion-button *ngIf="project?.canEdit && project?.subscriptionId && subscription.eventsSubIds.includes(project.subscriptionId); else noEditTpl" 
              slot="end" 
              (click)="editEventSubscription(subscription, project)" 
              fill="outline"
              class="round"
              color="primary" class="projects-action">
              <ion-icon name="create-outline" slot="icon-only"></ion-icon>
            </ion-button>
            <ng-template #noEditTpl>
              <ion-button 
                slot="end" 
                [routerLink]="[routeProjectDetail, project.id]"
                fill="outline"
                class="round"
                color="medium" class="projects-action">
                <ion-icon name="information" slot="icon-only"></ion-icon>
              </ion-button>
            </ng-template>
          </ion-item>
        </ion-list>
      </div>
      
    </div>

    <ng-container *ngIf="subscription.isActive">
      <ion-item *ngIf="minutesEnabled">
        <ion-label>
          <h3>Capture Minutes</h3>
          <p class="ion-text-wrap">The minutes and seconds of each clip you capture will deduct from your balance.</p>
        </ion-label>
        <ion-note slot="end" color="light" class="well">{{ minutes$ | async }}</ion-note>

        <ion-button
          slot="end"
          color="primary"
          title="Buy Minutes"
          size="default"
          (click)="addMinutes()">
          Buy Minutes
          <ion-icon slot="end" name="cart"></ion-icon>
        </ion-button>
        <!-- icon button:
        <ion-button
          slot="end"
          shape="round"
          fill="outline"
          color="light"
          title="Checkout"
          class="icon-btn"
          (click)="openCheckout($event)">
          <ion-icon slot="icon-only" name="cart-outline"></ion-icon>
        </ion-button> -->
      </ion-item>

      <ng-container *ngIf="subscription?.isActive && (subscription?.proSubId || subscription?.eventsSubIds?.length > 0)">
        <ion-grid class="grid-buttons">
          <ion-row>
            <ion-col size="12" size-sm="4">
              <ion-button
                fill="clear"
                class="text-btn"
                color="primary"
                [title]="'BILLING.INFO' | translate"
                (click)="openBillingInfo()">
                {{ 'BILLING.INFO' | translate }}
              </ion-button>
            </ion-col>
            <ion-col size="12" size-sm="4">
              <ion-button
                fill="clear"
                class="text-btn"
                color="primary"
                [title]="'BILLING.PAYMENT_METHODS' | translate"
                (click)="openPaymentMethods()">
                {{ 'BILLING.PAYMENT_METHODS' | translate }}
              </ion-button>
            </ion-col>
            <ion-col size="12" size-sm="4">
              <ion-button
                fill="clear"
                class="text-btn"
                color="primary"
                [title]="'BILLING.PURCHASE_HISTORY' | translate"
                (click)="openPurchaseHistory()">
                {{ 'BILLING.PURCHASE_HISTORY' | translate }}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ng-container>

    </ng-container>

  </ion-list>
</div>
