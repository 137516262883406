/** @format */

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import { selectMyProjects } from '@store/selectors/projects.selectors';
import { selectMyStack } from '@store/selectors/mystack.selectors';
import { UserService } from '@services/user.service';
import { combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { Stack, StackPublishInput } from '@shared/models/stack.model';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalComponent } from '@shared/components/ui/modal/base-modal.component';
import { PublishStackService } from '@services/publish-stack.service';
import { MyStackService } from '@services/mystack.service';

const DEBUG_LOGS = false;

@Component({
  selector: 'app-publish-modal',
  templateUrl: './publish-modal.component.html',
  styleUrls: ['./publish-modal.component.scss'],
})
export class PublishModalComponent implements OnInit, OnDestroy {
  // todo: MVP-1204 handle admins being able to edit the info on a stack
  @Input() isProjAdmin = false;

  @ViewChild('modal', { static: false, read: BaseModalComponent })
  modal: BaseModalComponent;

  isLoggedIn$ = this.userService.isLoggedIn$;
  userId$ = this.userService.userId$;

  myProjects$ = this.store.select(selectMyProjects);

  stack$ = this.store.select(selectMyStack).pipe(
    filter(Boolean),
    tap((stack: Stack) => {
      DEBUG_LOGS && console.log(`PublishModal stack:`, stack);
      // this.isStackLoading = false;
      if (Array.isArray(stack.clips)) {
        // check that the clips are done transcoding before publish
        // ref: mystack.component.html clip?.sources?.length > 0; else uploadingItem
        // const clipDoneTranscoding = stack.clips.filter(clip => getDoneTranscoding(clip));
        // this.hasStackClips = clipDoneTranscoding.length > 0;
        // this.hasStackClipsTranscoding = clipDoneTranscoding.length !== stack.clips.length;
        // // this.hasStackClips = !!stack.clips.length;
        // this.hasStackClipTooLong = stack.clips.some(
        //   (clip) => Utils.convertDurationToSeconds(clip.duration) > MAX_VIDEO_CAPTURE_LENGTH_SECONDS
        // );
      }
    })
    // shareReplay(1) // do we need refCount()?
  );

  /** internal saving for form to be used only in template */
  isFormSaving = false;
  isSaving = false;
  isPublishing = false;
  message = '';
  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private userService: UserService,
    private publishService: PublishStackService,
    private mystackService: MyStackService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    // this.returnUrl = this.router.url;
    combineLatest([this.stack$, this.userId$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([stack, userId]) => {
        if (stack.userId !== userId && this.isProjAdmin) {
          // todo: MVP-1204 handle admins being able to edit the info on a stack
          console.warn(`Todo: Allow isProjAdmin Edits`);
        }
      });
  }

  closeModal({ action = 'cancel', stack = {} }) {
    this.modal.dismiss({ action, stack });
  }

  async confirmPublishStack(stackDetails) {
    this.stack$.pipe(take(1)).subscribe(async (mystack) => {
      // the stack arg is only the modifications from stack-form-details, we also need the local stack
      const stack: Partial<Stack> = {
        ...mystack,
        ...stackDetails,
      };
      // confirm this will be published for the project to see, are you sure?
      const alert = await this.alertCtrl.create({
        header: this.translate.instant('EDITOR.PUBLISH.CONFIRM'),
        message: this.translate.instant('EDITOR.PUBLISH.CONFIRM_MSG'),
        buttons: [
          {
            text: this.translate.instant('EDITOR.PUBLISH.BUTTON_CANCEL'),
            role: 'cancel',
            handler: () => {
              this.saveStack(stack);
            },
          },
          {
            text: this.translate.instant('EDITOR.PUBLISH.BUTTON_WATCH'),
            handler: () => {
              try {
                this.saveStack(stack, 'play');
              } catch (error) {
                console.error(error);
                this.message = 'Oops! Save not successful.';
              }
            },
          },
          {
            text: this.translate.instant('EDITOR.PUBLISH.BUTTON_PROCEED'),
            handler: () => {
              this.publishStack(stack);
            },
          },
        ],
      });
      return await alert.present();
    });
  }

  async publishStack(stack: Partial<Stack>) {
    this.isPublishing = true;
    try {
      combineLatest([this.userId$, this.userService.username$])
        .pipe(take(1))
        .subscribe(async ([userId, username]) => {
          if (!(stack?.clips?.length > 0)) {
            // No playlist found - please add clips and try again
            this.isPublishing = false;
            this.message = this.translate.instant('ERRORS.NO_PLAYLIST_FOUND');
            return;
          }

          stack.userId = userId;
          stack.credits = username;
          if (stack.description === this.translate.instant('STACK.COLLAB_DEFAULT_STACK_DESC')) {
            // the default content should not get published
            stack.description = '';
          }

          const createdStack = await this.publishService.publishStack(stack as StackPublishInput);

          // in all cases resetMyStack
          this.mystackService.resetMyStack();

          this.closeModal({ action: 'publish', stack: createdStack });
          this.isPublishing = false;

          // doing this in the parent..
          // // now open the publish success modal
          // this.openPublishSuccessModal(createdStack);
        });
    } catch (error) {
      console.error(error);
      this.message = this.translate.instant('ERRORS.GENERIC_OOPS');
      this.isPublishing = false;
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * stack-form-details is no longer emitting an event, so this is internal to the modal
   */
  private saveStack(stack: Partial<Stack>, dismissAction = 'save') {
    console.log(`saveStack`, { stack });
    this.isSaving = true;

    try {
      combineLatest([this.userId$, this.userService.username$])
        .pipe(take(1))
        .subscribe(async ([userId, username]) => {
          stack.userId = userId;
          stack.credits = username;

          this.publishService.saveStackUpdates(stack); // dispatches actions

          setTimeout(() => {
            // just making it show loading for a bit, since we only dispatched actions here
            this.isSaving = false;
            this.closeModal({ action: dismissAction, stack });
          }, 900);
        });
    } catch (error) {
      console.error(error);
      this.message = this.translate.instant('ERRORS.GENERIC_OOPS');
      this.isSaving = false;
    }
  }
}
