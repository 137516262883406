<ion-toolbar class="top-navbar" *ngIf="showTopnav">
  <div class="container">
    <div class="row" style="display: flex; justify-content: space-between;">
      <!-- backBtnUrl -->
      <div
        [class.top-navbar--fullwidth-search]="inFullWidthSearch"
        [class.top-navbar--no-title]="!title"
        style="display: flex; width: 100%; justify-content: space-between;"
      >

        <ion-buttons slot="start" class="hide-in-fullwidth-search">
          <ion-back-button *ngIf="backButtonReturnUrl" 
            mode="ios"
            text=""
            [defaultHref]="backButtonReturnUrl" 
            style="padding-left:6px;"
          ></ion-back-button>

          <!-- *ngIf="!backButtonReturnUrl" -->
          <ion-button
            routerLink="/stack/home" 
            [ngSwitch]="theme"
            class="toolbar-title__logo">
            <!-- also need to handle xs-only breakpoint -->
            <ng-container *ngSwitchCase="'light'">
              <img *ngIf="!handleSplitLayout else logoBreakpoints"
                src="/assets/images/logos/Filmstacker-logo-teal.png"
                alt="Home"
                class="full-logo logo-teal"
              />
              <ng-template #logoBreakpoints>
                <img
                  src="/assets/images/logos/Filmstacker-logo-teal.png"
                  alt="Home"
                  class="logo-teal ion-hide-md-up"
                />
                <img
                  src="/assets/images/logos/filmstackerLogo.png"
                  alt="Home"
                  class="ion-hide-md-down"
                />
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <img 
                src="/assets/images/logos/fsr-f-32x32-lightGray.png"
                alt="Home"
                class="d-md-none mini-logo"
              />
              <img
                src="/assets/images/logos/filmstackerLogo.png"
                alt="Home"
                class="d-none d-md-block full-logo"
              />
            </ng-container>
          </ion-button>
        </ion-buttons>

        <ion-title *ngIf="title" class="main-title hide-in-fullwidth-search">
          <ng-container *ngIf="showtitleActionButton && drawerEnabled else noActionButton">
            <ion-button 
              fill="outline"
              [color]="isDrawerExpanded ? 'medium' : 'primary'"
              class="top-navbar__drawer-btn"
              [class.active]="isDrawerExpanded"
              (click)="onToggleDrawerClick($event)" 
              [title]="'COMMON.VIEW_DRAFTS' | translate">
              {{ title }}
              <ion-icon [name]="isDrawerExpanded ? 'chevron-up-outline' : 'chevron-down-outline'" class="no-pointer-events"></ion-icon>
            </ion-button>
          </ng-container>
          <ng-template #noActionButton>
            <a *ngIf="drawerEnabled else justTitle"
              href 
              class="top-navbar__drawer" 
              (click)="onToggleDrawerClick($event)" 
              [title]="'COMMON.VIEW_DRAFTS' | translate">
                {{ title }}
                <ion-icon [name]="isDrawerExpanded ? 'chevron-up-outline' : 'chevron-down-outline'" class="no-pointer-events"></ion-icon>
            </a>
            <ng-template #justTitle>
              {{ title }}
            </ng-template>
          </ng-template>
        </ion-title>

        <!-- fullwidth searchbar -->
        <app-searchbar slot="end"
          *ngIf="inFullWidthSearch && showSearch"
          [autofocus]="true"
          [responsiveWidth]="true"
          [placeholderText]="searchPlaceholderText"
          showCancelButton="always"
          [value]="(filters$ | async)?.q"
          (searchCancel)="setFullWidthSearch(false); onSearchCancel()"
          (searchEnter)="onSearch($event)"
          (searchChange)="onSearchChange($event)"
          (searchBlur)="onSearchBlur($event)"
        ></app-searchbar>
        
        <ion-buttons slot="end">
          <fs-button
            *ngIf="showSearch"
            color="medium"
            class="toolbar__fluid-search-btn hide-in-fullwidth-search"
            buttonStyle="clear"
            icon="search-outline"
            [iconOnly]="true"
            (click)="setFullWidthSearch(true)"
          ></fs-button>
          
          <!-- not logged in -->
          <ng-container *ngIf="(loggedIn$ | async) !== true; else userLoggedIn">

            <div *ngIf="showSignupBtn && !showLoginBtn && !showSearch" slot="end" class="login-intro-text">
              <fs-button
                class="text-btn"
                color=""
                buttonStyle="clear"
                routerLink="/signup"
                [queryParams]="{returnUrl}"
                >{{ 'AUTH.TOPNAV_NO_ACCOUNT' | translate }}</fs-button
              >
            </div>

            <fs-button *ngIf="showSignupBtn"
              class="d-none d-sm-block toolbar__auth-btn"
              routerLink="/signup"
              [queryParams]="{returnUrl}"
              style="margin-right: 10px; margin-left: 10px"
              >{{ 'AUTH.SIGNUP.ACTION' | translate }}</fs-button
            >

            <div *ngIf="showLoginBtn && !showSignupBtn && !showSearch" slot="end" class="login-intro-text">
              <fs-button
                class="text-btn"
                color=""
                buttonStyle="clear"
                routerLink="/login"
                [queryParams]="{returnUrl}"
              >
                <span class="has-account">{{ 'AUTH.TOPNAV_HAS_ACCOUNT' | translate }}</span>
                <span class="has-account-xs">{{ 'AUTH.TOPNAV_HAS_ACCOUNT_XS' | translate }}</span>
              </fs-button
              >              
            </div>
           
            <fs-button *ngIf="showLoginBtn"
              class="d-none d-sm-block toolbar__auth-btn"
              buttonStyle="outline"
              icon="person-outline"
              routerLink="/login"
              [queryParams]="{returnUrl}"
              >{{ 'AUTH.LOGIN.ACTION' | translate }}</fs-button
            >
            <fs-button
              class="d-sm-none toolbar__auth-btn"
              buttonStyle="outline"
              icon="person-outline"
              [routerLink]="showLoginBtn ? '/login' : '/signup'"
              [queryParams]="{returnUrl}"
              [iconOnly]="true"
              style="height:48px;"
            ></fs-button>
          </ng-container>

          <!-- logged in -->
          <ng-template #userLoggedIn>
            <ion-menu-toggle autoHide="false" menu="user-menu">
              <ion-buttons>
                <fs-button
                  *ngIf="avatar$ | async as avatarUrl"
                  style="padding-left: 10px;"
                  buttonStyle="clear"
                  [iconOnly]="true"
                >
                  <ion-avatar draggable="false">
                    <img [src]="avatarUrl" (error)="onAvatarError($event)" draggable="false" />
                  </ion-avatar>
                </fs-button>
                <fs-button
                  *ngIf="(avatar$ | async) === ''"
                  buttonStyle="clear"
                  icon="person-outline"
                  [iconOnly]="true"
                ></fs-button>
              </ion-buttons>
            </ion-menu-toggle>
          </ng-template>
        </ion-buttons>
      </div>
    </div>
  </div>
</ion-toolbar>

<ng-container *ngIf="drawerEnabled">
  <!-- [attr.hidden]="!isDrawerExpanded"  -->
  <app-top-navbar-drawer 
    *ngIf="isDrawerExpanded"
    @slideInOut 
    [items]="drawerItems$ | async"
    [handleActions]="drawerHandleActions"
    [label]="drawerLabel"
    [showLabel]="drawerShowLabel"
    [showingCreateForm]="drawerShowCreateForm"
    [selectedItemSuccess]="drawerSelectedItemSuccess"
    (closed)="isDrawerExpanded = false"
    (selected)="drawerSelected.emit($event)"
    (newItem)="drawerNewItem.emit($event)"
  ></app-top-navbar-drawer>
</ng-container>

<!-- slot content goes here -->
<ng-content></ng-content>

<!-- deprecated - but good code that can be re-used at some point
      <app-searchbar
        *ngIf="inFluidSearch"
        class="toolbar__fluid-searchbar"
        [responsiveWidth]="false"
        showCancelButton="always"
        [value]="(filters$ | async)?.q"
        (searchCancel)="setFluidSearch(false); onSearchCancel()"
        (searchEnter)="onSearch($event)"
        (searchChange)="onSearchChange($event)"
        class="d-none d-md-block"
      ></app-searchbar>
      <-- button to activate FLUID search for LARGER viewports ->
      <fs-button
        *ngIf="!inFluidSearch"
        class="d-none d-md-block toolbar__not-logged-in-btn"
        icon="search-outline"
        buttonStyle="clear"
        [iconOnly]="true"
        (click)="setFluidSearch(true)"
      ></fs-button>
      <-- button to activate FULLWIDTH search for SMALLER viewports ->
      <fs-button
        *ngIf="!inFluidSearch"
        class="d-md-none toolbar__not-logged-in-btn"
        icon="search-outline"
        buttonStyle="clear"
        [iconOnly]="true"
        (click)="setFullWidthSearch(true)"
      ></fs-button>
      -->

    <!-- fluid searchbar became too complex...removing 
          , only if no title 
        <app-searchbar
          [value]="(filters$ | async)?.q"
          [responsiveWidth]="false"
          showCancelButton="focus"
          (searchEnter)="onSearch($event)"
          (searchChange)="onSearchChange($event)"
          (searchCancel)="onSearchCancel()"
          class="d-none d-lg-block toolbar__fluid-searchbar hide-in-fullwidth-search"
        ></app-searchbar>
        <div class="d-lg-none toolbar__fluid-search-btn hide-in-fullwidth-search">
          <fs-button
            buttonStyle="clear"
            icon="search-outline"
            [iconOnly]="true"
            (click)="setFullWidthSearch(true)"
          ></fs-button>
        </div>
      -->
      