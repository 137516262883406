/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PROJECT_DETAIL_ROUTE, SIGNUP_PAGE } from '@app/app-routing.module';
import { Project } from '@projects/shared/project.model';
import { ProjectService } from '@projects/shared/services';
import { ToasterService } from '@services/toaster.service';
import { UserService } from '@services/user.service';
import { TokensService } from '@tokens/shared/services/tokens.service';
import { getReturnUrl, isTokenExpired, Token, TOKEN_QUERY_PARAM } from '@tokens/shared/tokens.model';
import { interval, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TRANSLATE_ROOT, DEFAULT_BACKGROUND_URL, DEFAULT_BACKGROUND_POSITION } from '../shared/onboarding.model';
import { OnboardingService } from '../shared/services/onboarding.service';

@Component({
  selector: 'app-onboarding-accept',
  templateUrl: './onboarding-accept.component.html',
  styleUrls: ['./onboarding-accept.component.scss'],
})
export class OnboardingAcceptComponent implements OnInit, OnDestroy {
  translatePath = TRANSLATE_ROOT;
  actionUrl = '/signup';
  routeId: string;
  backgroundUrl: string; // wait for this to get set to avoid flicker (MVP-1046) // = DEFAULT_BACKGROUND_URL;
  backgroundPosition = DEFAULT_BACKGROUND_POSITION;

  projectId: string;
  project: Project;
  inviteMessage = '';
  returnUrl = '';
  tokenId: string;
  isTokenExpired = true;

  isLoggedIn$ = this.userService.isLoggedIn$;

  private defaultBackgroundUrl = DEFAULT_BACKGROUND_URL;
  private token$: Observable<Token> = this.tokensService.token$;

  private onDestroy$ = new Subject<void>();

  constructor(
    private onboardingService: OnboardingService,
    private toaster: ToasterService,
    private tokensService: TokensService,
    private projectService: ProjectService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get('projectId');
    if (this.projectId) {
      this.returnUrl = `/${PROJECT_DETAIL_ROUTE}/${this.projectId}`;
    } else {
      this.returnUrl = 'studio';
    }

    // here we're setting the default backgroundUrl based on route metadata, in case the project DNE MVP-1046
    this.onboardingService.routeOnboardingMeta$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        ({
          routeId,
          translatePath = '',
          actionUrl = '',
          backgroundUrl = DEFAULT_BACKGROUND_URL,
          backgroundPosition = DEFAULT_BACKGROUND_POSITION,
        }) => {
          !environment.production &&
            console.log(`[OnboardingMeta]`, { routeId, translatePath, actionUrl, backgroundUrl, backgroundPosition });
          this.routeId = routeId;
          this.translatePath = translatePath;
          // this.actionUrl = actionUrl;
          this.defaultBackgroundUrl = backgroundUrl;
          if (this.backgroundUrl === this.defaultBackgroundUrl) {
            // fix: MVP-1046 do not flicker the default wedding graphic, await the project to load
            // this.backgroundUrl = backgroundUrl;
            this.backgroundPosition = backgroundPosition;
          }
        }
      );

    // get the token data
    this.token$.pipe(takeUntil(this.onDestroy$), filter(Boolean)).subscribe((token: Token) => {
      const {
        id,
        projectId,
        returnUrl,
        // projectRole,
        // eventType = '',
        payload: { returnUrl: payloadReturnUrl = '', message = '' } = {},
      } = token;

      this.tokenId = id;
      this.isTokenExpired = isTokenExpired(token);

      if (projectId || this.projectId) {
        this.projectService
          .getProjectPreview(projectId || this.projectId)
          .pipe(take(1))
          .subscribe((proj) => {
            !environment.production && console.log(`[Onboarding] project:`, proj);
            this.project = proj;
            this.backgroundUrl = proj && proj.hero ? proj.hero : this.defaultBackgroundUrl;
            // todo: should the project config hold this info?
            this.backgroundPosition = DEFAULT_BACKGROUND_POSITION;
          });
      } else {
        this.project = undefined;
        this.backgroundUrl = this.defaultBackgroundUrl;
      }
      this.inviteMessage = message;
      if (returnUrl) {
        this.returnUrl = returnUrl;
      } else if (payloadReturnUrl) {
        this.returnUrl = payloadReturnUrl;
      } else if (projectId || this.projectId) {
        this.returnUrl = getReturnUrl(projectId || this.projectId);
      } else {
        this.returnUrl = '/studio';
      }
      console.log(`[Accept] token:`, { token, returnUrl: this.returnUrl });
      if (token && token.success) {
        this.router.navigate([this.returnUrl]);
      }
    });

    // finally, let's wait a few seconds and update it if the token and/or project did not load
    // fix: MVP-1046 do not flicker the default wedding graphic, await the project to load
    interval(2000)
      .pipe(take(1))
      .subscribe(() => {
        if (!this.backgroundUrl) {
          this.backgroundUrl = this.defaultBackgroundUrl;
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /** per template, isLoggedIn$ == true */
  acceptInvite() {
    if (this.tokenId) {
      this.tokensService.applyTokenToCurrentUser({ tokenId: this.tokenId });
    }
  }

  signUp() {
    const returnUrl = this.returnUrl;
    const routeId = this.routeId ? this.routeId.toLowerCase() : '';
    this.router.navigate([`/${SIGNUP_PAGE}`, routeId], {
      state: { returnUrl },
      queryParams: { returnUrl, [TOKEN_QUERY_PARAM]: this.tokenId },
    });
  }
}
