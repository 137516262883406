/** @format */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@app/core/config';
import { ToasterService } from '@app/core/services/toaster.service';
import { UserService } from '@app/core/services/user.service';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as stackActions from '@store/actions/stacks.actions';
import { setAvatar } from '@store/actions/user.actions';
import { resetFilter } from '@store/actions/viewstate.actions';
import { selectAddToStackPlaylist } from '@store/selectors/stacks.selectors';
import { selectStackListItems } from '@store/selectors/lists.selectors';
import { FilterEntity, selectFilter, GLOBAL_QUERY_ID, FilterEntityTypes } from '@store/selectors/viewstate.selectors';
import { DEFAULT_USER_AVATAR } from '@shared/models/user.model';
import { Stack } from '@shared/models/stack.model';
import { ENABLE_SIGNUP } from '@app/app.config';
import { EventsService, EventActions } from '@services/events.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
// import { animate, style, transition, trigger } from '@angular/animations';
import { slideInOut } from '@app/animations';
import { DISCOVER_PAGE } from '@app/app-routing.module';

const DEBUG_LOGS = false;
/** at what screenWidth should the search placeholder text change from phone to desktop */
const SEARCH_TEXT_BREAKPOINT = 390;
const LIST_ID_TOP_DRAFTS = 'top_stack_drafts';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
  animations: [
    // needed for stack drawer
    slideInOut,
    // trigger('slideInOut', [
    //   transition(':enter', [
    //     style({ transform: 'translateY(-204px)', }),
    //     animate('450ms ease-in-out', style({ transform: 'translateY(0)' })),
    //   ]),
    //   transition(':leave', [
    //     style({ transform: 'translateY(0)' }),
    //     animate('450ms ease-in-out', style({ transform: 'translateY(-204px)' })),
    //   ]),
    // ]),
  ],
})
export class TopNavbarComponent implements OnInit, OnDestroy {
  @Input() title = '';
  @Input() showSearch = true;
  /** expand the search if values found in global filter */
  @Input() expandSearchIfQuery = false;
  @Input() showSignupBtn = ENABLE_SIGNUP; // allow to hide for unauth'd
  @Input() showLoginBtn = true; // allow to hide for unauth'd
  /** manages which logo */
  @Input() theme: 'light' | 'dark' = 'dark';
  @Input() handleSplitLayout = false;
  @Input() backButtonReturnUrl: string;
  @Input() drawerEnabled = false;
  @Input() drawerLabel: string;
  @Input() drawerShowLabel = true;
  @Input() drawerOpenOnLoad = false;
  @Input() drawerHandleActions = true;
  @Input() drawerSelectedItemSuccess = false;
  @Input() showtitleActionButton = false;
  @Input()
  set drawerExpandEvent(value: { i: number; showCreateForm?: boolean }) {
    const { i = 0, showCreateForm = false } = value;
    if (i > 0) {
      DEBUG_LOGS && console.log(`drawerExpandEvent`, value);
      this.drawerShowCreateForm = showCreateForm;
      this.isDrawerExpanded = true;
      // this.isDrawerExpanded = !!value;
    } // else this was an init event..
  }
  @Input()
  set drawerCloseEvent(value: number) {
    if (typeof value === 'number' && value > 0) {
      DEBUG_LOGS && console.log(`drawerCloseEvent`, value);
      this.isDrawerExpanded = false;
    } // else this was an init event..
  }

  @Output() drawerSelected = new EventEmitter<Partial<Stack>>();
  @Output() drawerNewItem = new EventEmitter<Partial<Stack>>();
  @Output() drawerExpand = new EventEmitter<boolean>();

  loggedIn$ = this.userService.isLoggedIn$;
  avatar$ = this.userService.avatarUrl$;
  showTopnav = true;
  inFullWidthSearch = false;
  // inFluidSearch = false;
  filters$: Observable<FilterEntity>;
  /** handle draewr open if no stack id, Input */
  isDrawerExpanded = false;
  drawerItems$: Observable<Stack[]>;
  drawerShowCreateForm = false;

  searchPlaceholderText: string = 'Discover Filmstacks & Projects';

  private _returnUrlQueryParam = '';
  get returnUrl() {
    return this._returnUrlQueryParam || this.router?.url;
  }

  private get hasSearchText(): boolean {
    return !!(this._searchText?.length > 0);
  }

  private _respondToPlaylistChanges = false; // await loading respondToPlaylistChanges
  private _placeholderTextDesktop = 'Discover Filmstacks & Projects';
  private _placeholderTextPhone = 'Find Stacks & Projects';

  /** this is used to determine what to do on searchBlur and on change */
  private _searchText = '';
  private onDestroy$ = new Subject<void>();
  private subscriptions: Subscription = new Subscription();
  private _closeDrawerTimeout;

  constructor(
    private events: EventsService,
    private userService: UserService,
    private configService: ConfigService,
    private analyticsService: AnalyticsService,
    private toaster: ToasterService,
    private store: Store<State>,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    /**
     * Determine if the widget config says to hide topnav
     */
    this.configService.appConfig.then((config) => {
      if (config.isWidgetActive && config.hideTopnav) {
        this.showTopnav = false;
      }
    });

    // carry the returnUrl through navs - does it need to be cleared?
    this.route.queryParams
      .pipe(
        takeUntil(this.onDestroy$),
        filter((params) => params?.returnUrl)
      )
      .subscribe((params) => {
        this._returnUrlQueryParam = params.returnUrl;
        DEBUG_LOGS && console.log(`returnUrlQueryParam='${this._returnUrlQueryParam}'`);
      });

    setTimeout(() => {
      this._respondToPlaylistChanges = true;
    }, 900);

    if (this.drawerEnabled) {
      // get the items here
      this.getStackDrafts();
      if (this.drawerOpenOnLoad) {
        DEBUG_LOGS && console.log(`topnav drawerOpenOnLoad`, this.drawerOpenOnLoad);
        this.isDrawerExpanded = true;
        this.drawerExpand.emit(this.isDrawerExpanded);
      }
      this.store
        .select(selectAddToStackPlaylist)
        .pipe(
          // delay(1000), /// wait for page to load and establish state
          takeUntil(this.onDestroy$)
          // filter((playlist) => Array.isArray(playlist) && playlist.length > 0)
        )
        .subscribe((playlist) => {
          if (!this._respondToPlaylistChanges) {
            return;
          }
          if (Array.isArray(playlist) && playlist.length > 0) {
            DEBUG_LOGS && console.log(`[topnav] playlist -> topDrawerExpand`);
            this.isDrawerExpanded = true;
            this.drawerExpand.emit(this.isDrawerExpanded);
          } else if (this.isDrawerExpanded) {
            DEBUG_LOGS && console.log(`[topnav] no playlist - no expand (with delay)`);
            this.delayedCloseDrawer(0); // no delay, reduce any time it's shown after nav if resetting..
          }
        });
    }

    /**
     * select the global filters from the store, memoized per MVP-970
     */
    this.filters$ = this.store.select(selectFilter(GLOBAL_QUERY_ID));

    /**
     * open the searchbar if there's a value for the inital filter query
     */
    this.filters$.pipe(takeUntil(this.onDestroy$)).subscribe((filt) => {
      DEBUG_LOGS &&
        console.log(`[TOPNAV] filter?.q ?`, { filter: filt, setFullWidth: !!(this.expandSearchIfQuery && filt?.q) });
      this._searchText = filt?.q ?? '';
      if (!!(this.expandSearchIfQuery && filt?.q)) {
        this.setFullWidthSearch(true);
      }
    });

    const subResize = this.events.subscribe(EventActions.RESIZE, this.onResize);
    this.subscriptions.add(subResize);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  delayedCloseDrawer(delay = 300) {
    clearTimeout(this._closeDrawerTimeout);
    this._closeDrawerTimeout = setTimeout(() => {
      this.isDrawerExpanded = false;
      this.drawerExpand.emit(this.isDrawerExpanded);
    }, delay);
  }

  /**
   * due to child being rendered by ngIf=isDrawerExpanded, this would get triggered mutliple times in child
   * which is ok since we are persisting & re-selecting the list from the store, loading if needed.
   */
  getStackDrafts() {
    const listId = LIST_ID_TOP_DRAFTS;
    // const list$: Observable<ItemList> = this.store.select(selectList(listId));
    this.drawerItems$ = this.store.select(selectStackListItems(listId));
    // load Stacks
    this.userService.userId$
      .pipe(
        filter((id) => id && id.length > 0),
        take(1)
      )
      .subscribe((userId) => {
        /**
         * when COLLABORATIVE DRAFTS ready, you can remove userId on this carousel
         */
        this.store.dispatch(
          stackActions.loadFilteredStacks({
            listId,
            filters: {
              // : FilterEntity
              type: FilterEntityTypes.StackDrafts,
              id: listId,
              q: null,
              isDraft: true,
              isTopDrawer: true,
              userId,
              // ...(this.projectId ? { projectId: this.projectId, userId } : { userId })
            },
          })
        );
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize = ({ width, height }) => {
    this.searchPlaceholderText =
      width > SEARCH_TEXT_BREAKPOINT ? this._placeholderTextDesktop : this._placeholderTextPhone;
  };

  setFullWidthSearch(mode: boolean) {
    DEBUG_LOGS &&
      console.log(`[topnav] setFullWidthSearch`, {
        showSearch: this.showSearch,
        inFullWidthSearch: this.inFullWidthSearch,
        mode,
        searchText: this._searchText,
      });
    if (this.showSearch) {
      this.inFullWidthSearch = mode;
    }
  }

  // setFluidSearch(mode: boolean) {
  //   this.inFluidSearch = mode;
  // }

  onSearchBlur(event) {
    DEBUG_LOGS && console.log(`[topnav] onSearchBlur`, { event, searchText: this._searchText });
    // if we click away and the page doesn't care about search then close it
    if (!this.hasSearchText || !this.expandSearchIfQuery) {
      this.setFullWidthSearch(false);
    }
  }

  onSearch(searchText: string) {
    DEBUG_LOGS && console.log(`[topnav] onSearch`, searchText);
    // this.setFilter(searchText); // could set it here before navigating, so the Lists load with latest Filter query & don't need to load twice?
    const queryParams: Partial<FilterEntity> = {}; // allow the any, since we don't need the required id in the queryparams
    if (searchText) {
      queryParams.q = searchText;
    }
    this._searchText = searchText; // keeping track of the searchText so we can clear when it changes to empty
    // before we nav, close this if the page we are on does not want to keep open
    if (!this.expandSearchIfQuery) {
      this.setFullWidthSearch(false);
    }
    this.navCtrl.navigateForward(`/${DISCOVER_PAGE}`, { queryParams });
    this.analyticsService.search(`[topnav]:${searchText}`);
  }

  onSearchChange(searchText: string) {
    DEBUG_LOGS && console.log(`[topnav] onSearchChange`, searchText);
    if (!searchText && searchText !== this._searchText) {
      this.onSearchCancel();
    } else {
      this._searchText = searchText;
    }
  }

  onSearchCancel() {
    DEBUG_LOGS && console.log(`[topnav] onSearchCancel...`);
    this.store.dispatch(resetFilter({ id: GLOBAL_QUERY_ID }));
    this._searchText = '';

    // remove the 'q' queryparam from the url
    this.router.navigate([], {
      queryParams: {
        q: null,
      },
      queryParamsHandling: 'merge',
      // i don't really want a location change,
      // but updating queryparams in url only appears to work if false?
      skipLocationChange: false,
    });
  }

  onToggleDrawerClick(evt) {
    evt.preventDefault();
    this.isDrawerExpanded = !this.isDrawerExpanded;
    this.drawerExpand.emit(this.isDrawerExpanded);
  }

  logout() {
    this.userService.logout();
    this.toaster.present(this.translate.instant('AUTH.TEXT_MESSAGE_ON_LOGOUT'));
  }

  onAvatarError(event) {
    console.log(`[topnav] onAvatarError`, event);
    this.store.dispatch(setAvatar({ avatar: DEFAULT_USER_AVATAR }));
  }
}
