/** @format */

import { createAction, props } from '@ngrx/store';
import { AddOn, ChargebeeCustomer, ChargebeeSubscription, Plan } from '../shared/billing.model';

export const doneLoadingNoUser = createAction('[Billing] Done Loading - No User');
export const loadPlans = createAction('[Billing] Load Plans');
export const loadPlansSuccess = createAction(
  '[Billing] Load Plans Success',
  props<{ plans: Plan[]; addons?: AddOn[] }>()
);

export const addCode = createAction('[Billing] Add Referral Code', props<{ code: string }>());

export const createCustomer = createAction(
  '[Billing] Create Customer'
  // props<{ userId: string; }>()
);

export const loadCustomerSubscriptions = createAction(
  '[Billing] Load Customer Subscriptions',
  props<{ userId: string }>()
);
export const loadCustomerSubscriptionsSuccess = createAction(
  '[Billing] Load Customer Subscriptions Success',
  props<{ customer: ChargebeeCustomer; subscriptions: ChargebeeSubscription[] }>()
);

export const reset = createAction('[Billing] Reset');
// export const setScores = createAction('[Scoreboard Page] Set Scores', props<{game: any}>());
