/** @format */

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ChargebeeService } from '@billing/shared/services/chargebee.service';
import { ChargebeePlanId } from '@billing/shared/billing.model';
import { BaseModalComponent } from '@shared/components/ui/modal/base-modal.component';
import { getSuccessPath } from '@onboarding/shared/onboarding.model';
@Component({
  selector: 'fs-checkout-modal',
  templateUrl: './checkout-modal.component.html',
  styleUrls: ['./checkout-modal.component.scss'],
})
export class CheckoutModalComponent implements OnInit {
  /** @todo HostedPage - move to environment */
  @Input() iframeSrc: SafeResourceUrl = '';
  @Input() userId = '';
  @Input() planId: ChargebeePlanId = ChargebeePlanId.Pro;
  @Input() priceId = '';
  @Input() referralCode = '';
  @Input() projectId = '';
  @Input() projectTitle = '';
  @Input() eventDate = '';
  @Input() successRedirectPath = getSuccessPath('');

  @ViewChild('modal', { static: false, read: BaseModalComponent })
  modal: BaseModalComponent;

  constructor(private chargebeeService: ChargebeeService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (!this.userId) {
      console.warn('UserId Missing');
      this.modal.dismiss({ data: { error: 'UserId Missing' } });
      return;
    }
    if (!this.iframeSrc) {
      // TODO: get Project info and event date
      this.chargebeeService
        .createCheckoutHostedPageId({
          user_id: this.userId,
          plan_id: this.planId,
          price_id: this.priceId,
          referral_code: this.referralCode,
          project_id: this.projectId,
          project_name: this.projectTitle,
          event_date: this.eventDate,
          // email = '',
          // first_name = '',
          // last_name = '',
          // phone = '',
          successRedirectPath: this.successRedirectPath,
        })
        .then((res) => {
          console.log(`checkout HostedPageId for '${this.userId}' plan: '${this.planId}':`, res);
          this.setFrameSrc(res.url);
        });
    }
  }

  setFrameSrc(url: string) {
    // using safeUrl pipe
    this.iframeSrc = url;
    // gives warning from safeUrl pipe
    // this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.sanitizer.sanitize(SecurityContext.URL, url));
  }
}
